import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    Chip,
    Typography,
    Avatar,
    Grid,
    Paper,
    Stack,
    useTheme,
    useMediaQuery,
    Skeleton,
} from "@mui/material";
import {
    ArrowBack,
    LocationOn,
    Visibility,
    BookmarkBorder,
    Phone,
    Message,
} from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import CustomPageHeader from '../../components/common/CustomPageHeader';
import useAd from '../../hooks/useAd';
import { useTranslation } from 'react-i18next';
import { getLocalizedDigits } from '@mui/x-date-pickers/internals/hooks/useField/useField.utils';
import getLocalizedValue from '../../utils/getLocalizedValue';


export default function AdInfo() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { Id } = useParams()
    const { getSelectedAdDetails, getSelectedAdLog, getSelectedAdHistory } = useAd()
    const [loading, setLoading] = useState(false)
    const [adDetails, setAdDetails] = useState({})
    const [adLogs, setAdLogs] = useState({})
    const [history, setHistory] = useState([])

    const fetchAdDetails = async (Id) => {
        setLoading(true);
        try {
            // Fetch both details and logs in parallel
            const [detailsResponse, logsResponse, historyResponse] = await Promise.all([
                getSelectedAdDetails(Id),
                getSelectedAdLog(Id),
                getSelectedAdHistory({
                    "type": "ad",
                    "id": Id
                })
            ]);

            // Handle the responses
            if (detailsResponse.success) {
                setAdDetails(detailsResponse.data);
            } else {
                console.error("Failed to fetch ad details:", detailsResponse.message);
            }

            if (logsResponse.success) {
                setAdLogs(logsResponse.data);
            } else {
                console.error("Failed to fetch ad logs:", logsResponse.message);
            }
            if (historyResponse.success) {
                setHistory(historyResponse.data);
            } else {
                console.error("Failed to fetch ad logs:", logsResponse.message);
            }
        } catch (error) {
            console.error("Error fetching ad details or logs:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (Id) {
            fetchAdDetails(Id)
        }

    }, [Id])

    return loading ? <AdInfoSkeleton /> : (
        <>
            <Box sx={{ p: { xs: 2, md: 3 }, bgcolor: '#f5f5f5', minHeight: '100vh' }}>
                {/* Header */}
                <CustomPageHeader heading={t('AD_INFO')} buttontext={t('BACK_TO_AD_LIST')} path='/ads' />
                        <Grid container spacing={3} marginTop={'20px'}>

                            <Grid item xs={12} md={8}>
                                <Stack spacing={2}>
                                    {/* Car Details Card */}
                                    <Card elevation={0}>
                                        <CardContent>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={4}>
                                                    <Box
                                                        component="img"
                                                        src={adDetails?.images ? adDetails?.images[0] : ''}
                                                        alt="Dodge Challenger SRT8"
                                                        sx={{
                                                            width: '100%',
                                                            height: 'auto',
                                                            borderRadius: 1,
                                                        }} />
                                                </Grid>
                                                <Grid item xs={12} sm={8}>
                                                    <Typography variant="h6" fontWeight='bold'>{adDetails.title}</Typography>
                                                    <Typography variant="h5" fontWeight='bold' sx={{ color: 'primary.main', my: 1 }}>
                                                        {adDetails.price}   {adDetails.currency}
                                                    </Typography>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                                        <LocationOn color='primary' sx={{ fontSize: 'large', mr: 1 }} />
                                                        <Typography variant="body2">{adDetails.country}</Typography>
                                                    </Box>
                                                    <Typography variant="body2" color="text.secondary">
                                                        {t('CATEGORY')}: <Typography fontWeight='bold' variant='' sx={{ color: 'primary.main', my: 1 }}> {adDetails?.category?.name?.en}</Typography>
                                                    </Typography>
                                                    <Typography variant="body2" color="text.secondary">
                                                        {t('ID')}: {adDetails.soldId}
                                                    </Typography>
                                                    <Button
                                                        variant="contained"
                                                        sx={{ mt: 2, bgcolor: 'primary', '&:hover': { bgcolor: '#e05a4a' } }}
                                                        onClick={() => navigate(`/ad-details/${Id}`)}
                                                    >
                                                        {t('VIEW_AD_DETAILS')}
                                                    </Button>

                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>

                                    {/* User Profile Card */}
                                    <Card elevation={0}>
                                        <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Avatar sx={{ width: 56, height: 56, mr: 2 }} src={adDetails?.postedBy?.profilePicture}></Avatar>
                                                <Box>
                                                    <Typography variant="subtitle1">{adDetails?.postedBy?.name}</Typography>
                                                    <Typography variant="body2" fontWeight='bold' color="primary.main">
                                                        Member Since 7 July 2024
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Button
                                                variant="contained"
                                                sx={{ bgcolor: 'primary', '&:hover': { bgcolor: '#e05a4a' } }}
                                                onClick={() => navigate(`/user/${adDetails?.postedBy?._id}`)}
                                            >
                                                {t('GO_TO_USER')}
                                            </Button>
                                        </CardContent>
                                    </Card>

                                    {/* Ad History */}
                                    <Card elevation={0}>
                                        <CardContent>
                                            <Typography variant="h6" sx={{ mb: 2 }}>
                                                {t('AD_HISTORY')}
                                            </Typography>
                                            <Stack spacing={1}>
                                                {history.map((item, index) => (
                                                    <Paper
                                                        key={index}
                                                        elevation={0}
                                                        sx={{
                                                            p: 2,
                                                            bgcolor: '#f8f8f8',
                                                            display: 'flex',
                                                            flexDirection: isMobile ? 'column' : 'row',
                                                            gap: 2,
                                                        }}
                                                    >
                                                        <Box sx={{ minWidth: 100 }}>
                                                            <Typography variant="body2" fontWeight='bold'>{item.date}</Typography>
                                                            <Typography variant="body2" color="text.secondary">
                                                                {item.adName}
                                                            </Typography>
                                                        </Box>
                                                        <Box sx={{ flexGrow: 1 }}>
                                                            <Typography variant="body1">{item.action}</Typography>
                                                            {item.plan.en && (

                                                                <Box>
                                                                    <Typography variant="body2" color="text.secondary">
                                                                        Plan: {getLocalizedValue(item.plan)}
                                                                    </Typography>
                                                                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                                        <Typography variant="body2">Days : </Typography>
                                                                        <Typography variant="body2" fontWeight='bold'>{item.validity} </Typography>
                                                                    </Box>
                                                                </Box>
                                                            )}
                                                        </Box>
                                                        {item.validity && (
                                                            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>

                                                                <Typography variant="body2" fontWeight='bold' sx={{ color: 'primary.main' }}>
                                                                    {item.amount}
                                                                </Typography>
                                                            </Box>
                                                        )}
                                                    </Paper>
                                                ))}
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                </Stack>
                            </Grid>
                        <Grid item xs={12} md={4}>
                            <Stack spacing={2}>
                                {/* Status */}
                                <Card elevation={0} style={{ padding: "30px" }}>
                                    <CardContent>
                                        <Stack spacing={2}>
                                            <Box sx={{ border: "1px solid ", p: 1, borderRadius: "5px" }}>
                                                <Typography variant="body1" fontWeight="bold">
                                                    {" "}
                                                    {t("STATUS")}
                                                </Typography>
                                                <Typography variant="body2" color="green" fontWeight="bold">
                                                    {adDetails?.status}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ border: "1px solid ", p: 1, borderRadius: "5px" }}>
                                                <Typography variant="body1" fontWeight="bold">
                                                    {" "}
                                                    {t("PUBLISHED_ON")}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color="primary.main"
                                                    fontWeight="bold"
                                                >
                                                    {adDetails?.createdAt}
                                                </Typography>
                                            </Box>
                                        </Stack>
                                    </CardContent>
                                </Card>

                                {/* Analytics */}
                                <Card elevation={0}>
                                    <CardContent>
                                        <Typography
                                            textAlign="center"
                                            fontWeight="bold"
                                            color="primary.main"
                                            variant="h6"
                                            sx={{ mb: 2 }}
                                        >
                                            {t("AD_ANALYTICS")}
                                        </Typography>
                                        <Stack spacing={2}>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    backgroundColor: "primary.main",
                                                    padding: "10px",
                                                    borderRadius: "3px",
                                                }}
                                            >
                                                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                                    <Visibility sx={{ color: "background.white" }} />
                                                    <Typography sx={{ color: "background.white" }}>
                                                        {t("VIEWS")}
                                                    </Typography>
                                                </Box>
                                                <Typography sx={{ color: "background.white" }}>
                                                    {adLogs.view || 0}
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    backgroundColor: "#f4a261",
                                                    padding: "10px",
                                                    borderRadius: "3px",
                                                }}
                                            >
                                                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                                    <BookmarkBorder sx={{ color: "background.white" }} />
                                                    <Typography sx={{ color: "background.white" }}>
                                                        {t("SAVES")}
                                                    </Typography>
                                                </Box>
                                                <Typography sx={{ color: "background.white" }}>
                                                    {adLogs.save || 0}
                                                </Typography>
                                            </Box>

                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    backgroundColor: "#e9c46a",
                                                    padding: "10px",
                                                    borderRadius: "3px",
                                                }}
                                            >
                                                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                                    <Phone sx={{ color: "background.white" }} />
                                                    <Typography sx={{ color: "background.white" }}>
                                                        {t("CALLS")}
                                                    </Typography>
                                                </Box>
                                                <Typography sx={{ color: "background.white" }}>
                                                    {adLogs.call || 0}
                                                </Typography>
                                            </Box>

                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    backgroundColor: "#2a9d8f",
                                                    padding: "10px",
                                                    borderRadius: "3px",
                                                }}
                                            >
                                                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                                    <Message sx={{ color: "background.white" }} />
                                                    <Typography sx={{ color: "background.white" }}>
                                                        {t("MSGS")}
                                                    </Typography>
                                                </Box>
                                                <Typography sx={{ color: "background.white" }}>
                                                    {adLogs.message || 0}
                                                </Typography>
                                            </Box>
                                        </Stack>
                                    </CardContent>
                                </Card>

                                {/* Analytics */}
                                <Card elevation={0}>
                                    <CardContent>
                                        <Typography
                                            textAlign="center"
                                            fontWeight="bold"
                                            color="primary.main"
                                            variant="h6"
                                            sx={{ mb: 2 }}
                                        >
                                            {t("UPDATE_STATUS")}
                                        </Typography>
                                        <Stack spacing={2}>
                                            {/* Action Buttons */}
                                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                                <Button
                                                    variant="contained"
                                                    sx={{
                                                        bgcolor: "primary",
                                                        "&:hover": { bgcolor: "#e05a4a" },
                                                        paddingX: "30px",
                                                    }}
                                                    onClick={() => navigate(`/update-status/${Id}`)}
                                                >
                                                    {t("UPDATE")}
                                                </Button>
                                            </Box>
                                        </Stack>
                                    </CardContent>
                                </Card>

                                {/* <Button
                    variant="contained"
                    fullWidth
                    startIcon={<Message />}
                    sx={{
                        bgcolor: 'primary', '&:hover': { bgcolor: '#e05a4a' }, padding: '20px', borderRadius: '2px',
                        // Adjust icon margin based on direction
                        '& .MuiButton-startIcon': {
                          marginLeft: theme.direction === 'rtl' ? '8px' : '0px',
                          marginRight: theme.direction === 'ltr' ? '8px' : '0px',
                        },
                      }}
                >
                    Send Message
                </Button> */}
                            </Stack>
                        </Grid>
                            {/* Main Content */}
                        </Grid>
               

            </Box>

        </>
    );
}

function AdInfoSkeleton() {
    return (
        <Box sx={{ p: { xs: 2, md: 3 }, bgcolor: "#f5f5f5", minHeight: "100vh" }}>
            {/* Skeleton for Header */}
            <Skeleton variant="rectangular" width="100%" height={60} sx={{ mb: 3 }} />

            <Grid container spacing={3} marginTop="20px">
                {/* Main Content Skeleton */}
                <Grid item xs={12} md={8}>
                    <Stack spacing={2}>
                        {/* Car Details Skeleton */}
                        <Card elevation={0}>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={4}>
                                        <Skeleton variant="rectangular" width="100%" height={150} />
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <Skeleton variant="text" width="70%" height={30} />
                                        <Skeleton
                                            variant="text"
                                            width="50%"
                                            height={25}
                                            sx={{ my: 1 }}
                                        />
                                        <Skeleton variant="text" width="80%" height={20} />
                                        <Skeleton variant="text" width="50%" height={20} />
                                        <Skeleton
                                            variant="rectangular"
                                            width="40%"
                                            height={36}
                                            sx={{ mt: 2 }}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>

                        {/* User Profile Skeleton */}
                        <Card elevation={0}>
                            <CardContent
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <Skeleton variant="circular">
                                        <Avatar />
                                    </Skeleton>
                                    <Box sx={{ ml: 2 }}>
                                        <Skeleton variant="text" width={120} />
                                        <Skeleton variant="text" width={100} />
                                    </Box>
                                </Box>
                                <Skeleton variant="rectangular" width={100} height={36} />
                            </CardContent>
                        </Card>

                        {/* Ad History Skeleton */}
                        <Card elevation={0}>
                            <CardContent>
                                <Skeleton
                                    variant="text"
                                    width="30%"
                                    height={30}
                                    sx={{ mb: 2 }}
                                />
                                <Stack spacing={2}>
                                    {Array(3)
                                        .fill(null)
                                        .map((_, index) => (
                                            <Skeleton
                                                key={index}
                                                variant="rectangular"
                                                width="100%"
                                                height={50}
                                                sx={{ borderRadius: 1 }}
                                            />
                                        ))}
                                </Stack>
                            </CardContent>
                        </Card>
                    </Stack>
                </Grid>

                {/* Sidebar Skeleton */}
                <Grid item xs={12} md={4}>
                    <Stack spacing={2}>
                        {/* Status Skeleton */}
                        <Card elevation={0}>
                            <CardContent>
                                <Stack spacing={2}>
                                    {Array(2)
                                        .fill(null)
                                        .map((_, index) => (
                                            <Skeleton
                                                key={index}
                                                variant="rectangular"
                                                width="100%"
                                                height={50}
                                            />
                                        ))}
                                </Stack>
                            </CardContent>
                        </Card>

                        {/* Analytics Skeleton */}
                        <Card elevation={0}>
                            <CardContent>
                                <Skeleton
                                    variant="text"
                                    width="50%"
                                    height={30}
                                    sx={{ mb: 2 }}
                                />
                                <Stack spacing={2}>
                                    {Array(4)
                                        .fill(null)
                                        .map((_, index) => (
                                            <Skeleton
                                                key={index}
                                                variant="rectangular"
                                                width="100%"
                                                height={50}
                                                sx={{ borderRadius: 1 }}
                                            />
                                        ))}
                                </Stack>
                            </CardContent>
                        </Card>

                        {/* Button Skeleton */}
                        <Skeleton variant="rectangular" width="100%" height={50} />
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
}
