import * as React from "react";
import i18n from 'i18next';
import Box from "@mui/material/Box";
import {
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { styled } from "@mui/material/styles";
import CustomTable from "../../components/common/CustomTable";
import CustomTabPanel from "../../components/common/CustomTabPanel";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import useSpecification from "../../hooks/useSpecifications";
import { useState } from "react";
import getLocalizedValue from "../../utils/getLocalizedValue";
import { ArrowBack } from "@mui/icons-material";
import CustomPageHeader from "../../components/common/CustomPageHeader";
import { useTranslation } from "react-i18next";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function SpecificationEditPage() {
  const { t } = useTranslation();
  // Columns definition
  const columns = [
    { id: "id", label: "", align: i18n.language == 'en' ? 'left': 'right' },
    { id: "name", label: t("LIST_ITEMS"), align: i18n.language == 'en' ? 'left': 'right' },
    { id: "action", label: t("ACTION"), align: "center" },
  ];
  const { id } = useParams();
  const navigate = useNavigate();
  const [item, setItem] = useState({});
  const [listItems, setListItems] = useState([]);
  const [listToDisplay, setListToDisplay] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [open, setOpen] = useState(false);
  const [nameValue, setNameValue] = useState(0);
  const [iconImage, setIconImage] = useState(null);
  const [dialogTabValue, setDialogTabValue] = useState(0);
  const [specification, setSpecification] = useState(null);
  const {
    createSpecifications,
    getSpecificationById,
    updateSpecifications,
    deleteSpecifications,
  } = useSpecification();
  const [value, setValue] = useState(2);

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const handleNameValChange = (newValue) => {
    setNameValue(newValue);
  };

  const formik = useFormik({
    initialValues: {
      name: {
        en: "",
        ar: "",
      },
      inputProps: "text",
    },
    onSubmit: async (values) => {
      try {
        if (!values.name.en || values.name.en.trim() === "") {
          alert("The English name (en) is required.");
          return;
        }
        const formData = prepareFormData(values);

        const response = id
          ? await updateSpecifications(id, formData)
          : await createSpecifications(formData);

        if (response.success) {
          formik.resetForm(); // Reset form after successful submission
          navigate("/specification"); // Navigate to the category list page
        } else {
          alert("Oops.. Something went wrong.");
        }
      } catch (error) {
        console.error("Error during form submission:", error);
        alert("Oops.. Something went wrong.");
      }
    },
  });

  const prepareFormData = (values) => {
    const formData = new FormData();
    if (iconImage) {
      formData.append("iconImage", iconImage[0]);
    }
    formData.append("name", JSON.stringify(values.name));
    formData.append(
      "type",
      value === 0
        ? "Dropdown List"
        : value === 1
        ? "Input Number Range"
        : "Input"
    );
    if (listItems.length > 0 && value === 0) {
      const listItemsWithOrder = listItems.map((item, index) => ({
        name: item?.name,
        displayOrder: index + 1,
      }));
      formData.append("listItems", JSON.stringify(listItemsWithOrder));
    } else if (value === 1) {
      formData.append("range", JSON.stringify(values.range));
    } else if (value === 2) {
      formData.append("inputProps", values.inputProps);
    }
    return formData;
  };

  const handleFileChange = (event) => {
    const file = event.target.files;
    if (file) {
      setIconImage([...file]);
    }
  };

  const handleDeleteListItem = () => {
    if (selectedItem.length <= 0) {
      alert("Please select an item to delete.");
    } else {
      if (!window.confirm("Are you sure you want to delete this item?")) {
        return;
      }
      const updatedListItems = listItems.filter(
        (item) => !selectedItem.includes(item.id)
      );
      updateList(updatedListItems);
    }
  };

  const handleDeleteCategory = () => {
    if (id) {
      if (!window.confirm("Are you sure you want to delete this category?")) {
        return;
      }

      const payload = { ids: [id] };

      deleteSpecifications(payload).then((response) => {
        if (response.success) {
          navigate("/specification");
        } else {
          alert("Oops.. Something went wrong.");
        }
      });
    } else {
      navigate("/specification");
    }
  };

  const handleClose = () => {
    resetForm();
  };

  const handleSelection = (id, updatedListItems, selectedItemIds = []) => {
    const updatedSelection = selectedItemIds.includes(id)
      ? selectedItemIds.filter((selectedId) => selectedId !== id)
      : [...selectedItemIds, id];
    setSelectedItem(updatedSelection);
    updateList(updatedListItems, updatedSelection);
  };

  const handleSave = () => {
    if (!item.en || item.en.trim() === "") {
      alert("The English name (en) is required.");
      return;
    }

    const isDuplicate = listItems.some(
      (listItem) =>
        listItem?.name?.en?.toLowerCase() === item?.en?.toLowerCase() &&
        listItem?.id !== item?.id
    );

    if (isDuplicate) {
      alert("Duplicate entry is not allowed.");
      return;
    }

    const updatedListItems = [...listItems];

    if (editingIndex !== null) {
      updatedListItems[editingIndex] = {
        id: updatedListItems[editingIndex].id,
        name: item,
      };
    } else {
      const lastItemId =
        updatedListItems.length > 0
          ? Math.max(...updatedListItems.map((item) => item.id))
          : 0;
      const newId = lastItemId + 1;

      updatedListItems.push({
        id: newId,
        name: item,
      });
    }

    updateList(updatedListItems);

    resetForm();
  };

  // Centralized update function for list display
  const updateList = (updatedListItems, updatedSelections) => {
    const selectedItemIds = updatedSelections || selectedItem;
    setSelectedItem(selectedItemIds);
    setListItems(updatedListItems);
    setListToDisplay(
      updatedListItems.map((listItem) => ({
        id: (
          <Box sx={{ maxWidth: "10px" }}>
            <Checkbox
              checked={selectedItemIds.includes(listItem?.id)}
              onChange={() =>
                handleSelection(listItem?.id, updatedListItems, selectedItemIds)
              }
            />
          </Box>
        ),
        name: getLocalizedValue(listItem.name),
        action: (
          <Button
            size="small"
            variant="outlined"
            color="black"
            sx={{
              textTransform: "inherit",
              padding: "0",
              minWidth: "45px",
              fontWeight: "bold"
            }}
            onClick={() => handleEdit(listItem.id, updatedListItems)} // Pass unique ID for editing
          >
            {t('EDIT')}
          </Button>
        ),
      }))
    );
    resetForm();
  };

  // Centralized form reset function
  const resetForm = () => {
    setItem({ en: "", ar: "" });
    setEditingIndex(null);
    setOpen(false);
  };

  const handleEdit = (id, updatedListItems) => {
    const index = updatedListItems.findIndex((item) => item.id === id);
    if (index !== -1) {
      setItem(updatedListItems[index]?.name || "");
      setEditingIndex(index); // Store the index for editing
      setOpen(true);
    }
  };

  useEffect(() => {
    if (id) {
      getSpecificationById(id).then((res) => {
        if (res.success) {
          const specificationData = res.data;
          if (
            specificationData?.type === "Dropdown List" &&
            specificationData?.listItems?.length > 0
          ) {
            const updatedListItems = specificationData.listItems.map(
              (item, index) => ({
                ...item,
                id: index,
              })
            );
            setSpecification({
              ...specificationData,
              listItems: updatedListItems,
            });
          } else {
            setSpecification(specificationData);
          }
        } else {
          setSpecification(null);
        }
      });
    }
  }, [id]);

  useEffect(() => {
    if (specification && id) {
      formik.setValues({
        name: {
          en: specification?.name?.en,
          ar: specification?.name?.ar,
        },
        listItems: specification?.listItems,
        type: specification?.type,
        range: specification?.range,
        inputProps: specification?.inputProps,
      });

      setValue(
        specification?.type === "Dropdown List"
          ? 0
          : specification?.type === "Input Number Range"
          ? 1
          : 2
      );

      if (specification.iconImage) {
        setIconImage(specification.iconImage);
      }

      if (specification?.listItems?.length > 0) {
        const updatedListItems = specification?.listItems;
        setListItems(updatedListItems);
        setListToDisplay(
          updatedListItems?.map((item) => ({
            id: (
              <Checkbox
                checked={selectedItem?.includes(item?.id)}
                onChange={() => handleSelection(item?.id, updatedListItems)}
              />
            ),
            name: getLocalizedValue(item?.name),
            action: (
              <Button
                size="small"
                variant="outlined"
                color="black"
                sx={{
                  textTransform: "inherit",
                  padding: "0",
                  minWidth: "45px",
                  fontWeight: "bold"
                }}
                onClick={() =>
                  handleEdit(item?.id, updatedListItems, selectedItem)
                } // Consistent ID used here
              >
                {t('EDIT')}
              </Button>
            ),
          }))
        );
      }
    }
  }, [specification]);

  return (
    <Box sx={{ width: "100%" }}>
      <CustomPageHeader
        heading={`${id ? t('EDIT') : t('ADD')} ${t('SPECIFICATION_ITEM')}`}
        buttontext={t('SPECIFICATION_ITEM_BACK')}
        path="/specification"
      />
      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", padding: "20px", mt: "20px" }}
      >
        <Container maxWidth="sm">
          <Box style={{ display: "flex", width: "100%" }}>
            <Button
              variant={nameValue === 0 ? "contained" : "text"}
              style={{
                borderRadius: "0px",
                width: "50%",
                fontSize: "18px",
                fontWeight: "bold",
                textTransform: "inherit",
                height: "35px"
              }}
              onClick={() => handleNameValChange(0)}
            >
              {t('ENGLISH')}
            </Button>
            <Button
              variant={nameValue === 1 ? "contained" : "text"}
              style={{
                borderRadius: "0px",
                width: "50%",
                fontSize: "18px",
                fontWeight: "bold",
                textTransform: "inherit",
                height: "35px"
              }}
              onClick={() => handleNameValChange(1)}
            >
              {t('ARABIC')}
            </Button>
          </Box>
          <CustomTabPanel value={nameValue} index={0}>
            <Typography variant="body1" fontWeight="bold">
              {t('SPECIFICATION_NAME')}
            </Typography>
            <TextField
              fullWidth
              size="small"
              name="name.en"
              value={formik.values?.name?.en}
              onChange={formik.handleChange}
            />
          </CustomTabPanel>
          <CustomTabPanel value={nameValue} index={1} dir="rtl">
            <Typography variant="body1" fontWeight="bold">
              {t('SPECIFICATION_NAME')}
            </Typography>
            <TextField
              fullWidth
              size="small"
              name="name.ar"
              value={formik.values?.name?.ar}
              onChange={formik.handleChange}
            />
          </CustomTabPanel>
        </Container>
      </Paper>

      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "30px" }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
              gap: 5,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Typography fontWeight="bold" variant="body2">
                {t('ICON_IMAGE')}
              </Typography>
              <Box
                sx={{
                  height: "70px",
                  width: "70px",
                  backgroundColor: "primary.main",
                  borderRadius: "10px",
                }}
              >
                {iconImage && (
                  <img
                    style={{
                      height: "70px",
                      width: "70px",
                      borderRadius: "10px",
                      objectFit: "contain",
                    }}
                    src={
                      typeof iconImage === "string"
                        ? iconImage
                        : URL.createObjectURL(iconImage[0])
                    }
                    alt="icon preview"
                  />
                )}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                sx={{
                  textTransform: "inherit",
                  fontWeight: "bold"
                }}
              >
                {t('UPLOAD_IMAGE')}
                <VisuallyHiddenInput
                  type="file"
                  onChange={handleFileChange}
                  multiple
                />
              </Button>
              <Typography fontWeight="bold" variant="body2">
                {t('IMAGE_SIZE')}
              </Typography>
            </Box>
          </Box>
        </Container>
      </Paper>

      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "30px" }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          textAlign="center"
          color="primary"
        >
          {t('SELECT_SPECIFICATION_TYPE')}
        </Typography>
        <Container maxWidth="md">
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 6,
              width: "100%",
              margin: "20px 0px",
            }}
          >
            <Button
              variant={value === 0 ? "contained" : "outlined"}
              style={{
                borderRadius: "25px",
                width: "30%",
                fontSize: "14px",
                fontWeight: "bold",
                textTransform: "inherit",
              }}
              onClick={() => handleChange(0)}
            >
              {t('DROPDOWN_LIST')}
            </Button>
            <Button
              variant={value === 1 ? "contained" : "outlined"}
              style={{
                borderRadius: "25px",
                width: "30%",
                fontSize: "14px",
                fontWeight: "bold",
                textTransform: "inherit",
              }}
              onClick={() => handleChange(1)}
            >
              {t('INPUT_NUMBER_RANGE')}
            </Button>
            <Button
              variant={value === 2 ? "contained" : "outlined"}
              style={{
                borderRadius: "25px",
                width: "30%",
                fontSize: "14px",
                fontWeight: "bold",
                textTransform: "inherit",
              }}
              onClick={() => handleChange(2)}
            >
              {t('INPUT')}
            </Button>
          </Box>
        </Container>
        <CustomTabPanel value={value} index={0}>
          <Typography
            variant="body1"
            fontWeight="bold"
            textAlign="center"
            color="primary">
            {t('SELECT_LIST_ITEMS')}
          </Typography>
          <CustomTable
            data={listToDisplay}
            columns={columns}
            hidePagination={true}
          />
          <Container maxWidth="sm">
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "2rem",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                sx={{ mx: "5px", textTransform: "inherit", minWidth: "150px", fontWeight: "bold", height: "40px" }}
                onClick={() => {
                  setOpen(true);
                }}
              >
                {t('ADD_LIST_ITEM')}
              </Button>
              <Dialog
                open={open}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle
                  textAlign="center"
                  variant="h5"
                  fontWeight="bold"
                  color="primary"
                >
                  {t('ADD_ITEM')}
                </DialogTitle>
                <DialogContent sx={{ width: "500px" }}>
                  <Container>
                    <Box style={{ display: "flex", width: "100%" }}>
                      <Button
                        variant={dialogTabValue === 0 ? "contained" : "text"}
                        style={{
                          borderRadius: "0px",
                          width: "50%",
                          fontSize: "18px",
                          fontWeight: "bold",
                          textTransform: "inherit",
                          height: "35px"
                        }}
                        onClick={() => setDialogTabValue(0)}
                      >
                        {t('ENGLISH')}
                      </Button>
                      <Button
                        variant={dialogTabValue === 1 ? "contained" : "text"}
                        style={{
                          borderRadius: "0px",
                          width: "50%",
                          fontSize: "18px",
                          fontWeight: "bold",
                          textTransform: "inherit",
                          height: "35px"
                        }}
                        onClick={() => setDialogTabValue(1)}
                      >
                        {t('ARABIC')}
                      </Button>
                    </Box>
                    <CustomTabPanel value={dialogTabValue} index={0}>
                      <Typography variant="body1" fontWeight="bold">
                      {t('ITEM_NAME')}
                      </Typography>
                      <TextField
                        fullWidth
                        size="small"
                        name="name.en"
                        value={item?.en}
                        onChange={(e) => {
                          setItem({ ...item, en: e.target.value });
                        }}
                      />
                    </CustomTabPanel>
                    <CustomTabPanel value={dialogTabValue} index={1} dir="rtl">
                      <Typography variant="body1" fontWeight="bold">
                        {t('ITEM_NAME')}
                      </Typography>
                      <TextField
                        fullWidth
                        size="small"
                        name="name.ar"
                        value={item?.ar}
                        onChange={(e) =>
                          setItem({ ...item, ar: e.target.value })
                        }
                      />
                    </CustomTabPanel>
                  </Container>
                </DialogContent>
                <DialogActions>
                  <Button
                    sx={{
                      textTransform: "inherit",
                      fontWeight: "bold"
                    }}
                    onClick={handleClose}
                  >{t('CANCEL')}</Button>
                  <Button
                    sx={{
                      textTransform: "inherit",
                      fontWeight: "bold"
                    }}
                    onClick={handleSave}
                  >{t('SAVE')}</Button>
                </DialogActions>
              </Dialog>
              <Button
                variant="contained"
                color="secondary"
                sx={{ mx: "5px", textTransform: "inherit", minWidth: "150px", fontWeight: "bold", height: "40px" }}
                onClick={handleDeleteListItem}
              >
                {t('DELETE_LIST_ITEM')}
              </Button>
            </Box>
          </Container>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Container maxWidth="sm">
            <Typography
              variant="body1"
              fontWeight="bold"
              color="primary"
              textAlign="center"
              style={{ margin: "20px 0px" }}
            >
              {t('ENTER_RANGE_VALUES')}
            </Typography>
            <Box
              style={{
                display: "flex",
                width: "100%",
                gap: "20px",
                paddingY: "20px",
              }}
            >
              <Box style={{ width: "100%" }}>
                <Typography variant="body2" fontWeight="bold" textAlign="center">
                  {t('RANGE_VALUE_FROM')}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  name="range.min"
                  type="number"
                  onChange={formik.handleChange}
                  value={formik.values?.range?.min}
                />
              </Box>
              <Box style={{ width: "100%" }}>
                <Typography variant="body2" fontWeight="bold" textAlign="center">
                  {t('RANGE_VALUE_TO')}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  name="range.max"
                  type="number"
                  onChange={formik.handleChange}
                  value={formik.values?.range?.max}
                />
              </Box>
            </Box>
          </Container>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Container maxWidth="sm">
            <Typography
              variant="body1"
              fontWeight="bold"
              color="primary"
              textAlign="center"
              style={{ margin: "20px 0px" }}
            >
              {t('SELECT_VALUES')}
            </Typography>
            <Box
              style={{
                display: "flex",
                width: "100%",
                gap: "20px",
                paddingY: "20px",
              }}
            >
              <Box style={{ width: "100%" }}>
                <FormControl fullWidth>
                  <Typography fontWeight="bold" variant="body2">
                    {t('INPUT_PROPS')}
                  </Typography>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formik.values.inputProps}
                    name="inputProps"
                    onChange={formik.handleChange}
                  >
                    <MenuItem value={"text"}>{t('TEXT')}</MenuItem>
                    <MenuItem value={"number"}>{t('NUMBER')}</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Container>
        </CustomTabPanel>
      </Paper>

      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "30px" }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{ mx: "5px", height: "40px", textTransform: "inherit", minWidth: "150px", fontWeight: "bold" }}
              size="large"
              onClick={formik.handleSubmit}
            >
              {t('SAVE')}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              sx={{ mx: "5px", height: "40px", textTransform: "inherit", minWidth: "150px", fontWeight: "bold" }}
              size="large"
              onClick={handleDeleteCategory}
            >
              {t('DELETE')}
            </Button>
          </Box>
        </Container>
      </Paper>
    </Box>
  );
}

export default SpecificationEditPage;
