
import React, { useEffect, useState } from 'react';
import {
    Box,
    Paper,
    Typography,
    Button,
    Avatar,
    useTheme,
    useMediaQuery,
    Skeleton,
} from '@mui/material';
import {
    LocationOn,
   
} from '@mui/icons-material';
import CustomPageHeader from '../../components/common/CustomPageHeader';
import AdPreviewMap from '../../components/common/AdPreviewMap';
import { useParams } from 'react-router-dom';
import useAd from '../../hooks/useAd';
import getLocalizedValue from '../../utils/getLocalizedValue';
const googleMapsApiKey = process.env.GOOGLE_MAP_API_KEY;


function AdDetails() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const { Id } = useParams()
    const { getSelectedAdDetails} = useAd()
    const [loading, setLoading] = useState(false)
    const [adDetails, setAdDetails] = useState({})
 

    const fetchAdDetails = async (Id) => {
        setLoading(true);
        try {
            // Fetch both details and logs in parallel
            const detailsResponse = await getSelectedAdDetails(Id);

            // Handle the responses
            if (detailsResponse.success) {
                setAdDetails(detailsResponse.data);
            } else {
                console.error("Failed to fetch ad details:", detailsResponse.message);
            }

    
        } catch (error) {
            console.error("Error fetching ad details or logs:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (Id) {
            fetchAdDetails(Id)
        }

    }, [Id])

    if (loading) {
        return <AdDetailsSkeleton />;
      }

    return (
        <Box>

            <CustomPageHeader heading='Ad Details' buttontext='back to Ad Info' path={`/ad-info/${Id}`} />


            {/* Main Content */}
            <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: 3, marginTop: '30px'}}>
                <Box sx={{ flex: 1 }}>
                    <Paper elevation={0} sx={{ mb: 3  }}>
                        {loading ? (
                            <Skeleton variant="rectangular" height={300} />
                        ) : (
                            <Box sx={{aspectRatio:1/1}}>
                                <img src={adDetails?.images ? adDetails?.images[0] : ""} style={{aspectRatio:1/1,width:'100%'}}
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = "https://via.placeholder.com/150";
                                  }}
                                alt='sold'/>
                            </Box>
                        )}
                    </Paper>


                </Box>

                <Box sx={{ flex: 2 }}>
                    <Paper  elevation={0} sx={{ p: 3, mb: 3 }}>
                        <Box sx={{ display: 'flex',flexDirection: isMobile ? 'column' : 'row', gap: 5 }}>
                            <Box sx={{flex:2}}>
                                <Typography variant="h5" gutterBottom>
                                
                                </Typography>
                                <Typography variant="h4" sx={{ color: '#F06B5B', mb: 2 }}>
                                {adDetails.price}  {adDetails.currency}
                                </Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                    <LocationOn sx={{ color: '#F06B5B', mr: 1 }} />
                                    <Typography> location </Typography>
                                </Box>
                                <Typography color="text.secondary">
                                    Category: {getLocalizedValue(adDetails?.category?.name) }
                                </Typography>
                                <Typography color="text.secondary">
                                    ID: {adDetails.soldId}
                                </Typography>
                            </Box>
                            <Box sx={{display:'flex', flex:1, alignItems:'center' , flexDirection:'column'}}>
                                <Typography variant="h6" sx={{ color: '#F06B5B'}}>
                                    Location
                                </Typography>
                                <Typography gutterBottom>{adDetails.country}</Typography>
                                <Box sx={{ mb: 2, display: "flex", justifyContent: "center" }}>
                                    {loading ? (
                                        <Skeleton variant="rectangular" width={300} height={200} />
                                    ) : (
                                        <AdPreviewMap
                                            apiKey={googleMapsApiKey}
                                            latitude={
                                                adDetails?.location?.markerPosition?.lat ||
                                                10.011145
                                            }
                                            longitude={
                                                adDetails?.location?.markerPosition?.lng ||
                                                76.364964
                                            }
                                        />
                                    )}
                                </Box>
                            </Box>
                        </Box>

                    </Paper>

                    <Paper elevation={0} sx={{ p: 3, mb: 3, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <Avatar sx={{ width: 56, height: 56 }} src={adDetails?.postedBy?.profilePicture}>ZA</Avatar>
                            <Box>
                                <Typography variant="subtitle1">{adDetails?.postedBy?.name}</Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Member Since {adDetails?.postedBy?.createdAt}
                                </Typography>
                            </Box>
                        </Box>
                        <Button
                            variant="contained"
                            sx={{ bgcolor: '#F06B5B', '&:hover': { bgcolor: '#e05a4a' } }}
                        >
                            Go to User
                        </Button>
                    </Paper>
                </Box>
            </Box>
            {/* Main Content */}
            <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: 3, marginTop: '30px' }}>
                <Box sx={{ flex: 1 }}>

                    <Paper elevation={0} sx={{ p: 3, mb: 3 }}>
                        <Typography variant="h6" sx={{ color: '#F06B5B', mb: 2 }}>
                            Specifications
                        </Typography>
                        <Box sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
                            gap: 2
                        }}>
                            { adDetails?.specifications && Object.keys(adDetails?.specifications).map((key , index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1,
                                        p: 1,
                                        bgcolor: 'rgba(240, 107, 91, 0.1)',
                                        borderRadius: 1,
                                    }}
                                >
                                    <Typography> {key} : {adDetails?.specifications[key]}</Typography>
                                </Box>
                            ))}
                        </Box>
                    </Paper>
                </Box>

                <Box sx={{ flex: 1 }}>


                    <Paper elevation={0} sx={{ p: 3, mb: 3 }}>
                        <Typography variant="h6" sx={{ color: '#F06B5B', mb: 2 }}>
                            Additional Info
                        </Typography>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                            {adDetails?.additionalDetails && Object.keys(adDetails?.additionalDetails).map((key, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        bgcolor: 'rgba(240, 107, 91, 0.1)',
                                        px: 2,
                                        py: 0.5,
                                        borderRadius: 1,
                                    }}
                                >
                                    <Typography> {key} </Typography>
                                </Box>
                            ))}
                        </Box>
                    </Paper>

                    <Paper elevation={0} sx={{ p: 3 }}>
                        <Typography variant="h6" sx={{ color: '#F06B5B', mb: 2 }}>
                            Description
                        </Typography>
                        <Typography variant="body2" color="text.secondary">{adDetails.description}</Typography>
                    </Paper>
                </Box>
            </Box>
        </Box>
    );
}
export default AdDetails

function AdDetailsSkeleton() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  
    return (
      <Box sx={{ maxWidth: 1200, mx: 'auto', p: 3 }}>
        {/* Page Header Skeleton */}
        <Skeleton variant="rectangular" height={40} sx={{ mb: 3 }} />
  
        {/* Main Content */}
        <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: 3, marginTop: '30px' }}>
          {/* Left Column */}
          <Box sx={{ flex: 1 }}>
            {/* Image Section */}
            <Paper sx={{ mb: 3, p: 2 }}>
              <Skeleton variant="rectangular" height={300} />
            </Paper>
          </Box>
  
          {/* Right Column */}
          <Box sx={{ flex: 2 }}>
            {/* Car Details */}
            <Paper sx={{ p: 3, mb: 3 }}>
              <Skeleton variant="text" height={40} width="60%" sx={{ mb: 2 }} />
              <Skeleton variant="text" height={30} width="40%" sx={{ mb: 2 }} />
              <Skeleton variant="text" height={20} width="80%" sx={{ mb: 1 }} />
              <Skeleton variant="text" height={20} width="50%" />
            </Paper>
  
            {/* User Profile */}
            <Paper sx={{ p: 3, mb: 3, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Skeleton variant="circular" width={56} height={56} />
                <Box>
                  <Skeleton variant="text" height={20} width={100} />
                  <Skeleton variant="text" height={15} width={150} />
                </Box>
              </Box>
              <Skeleton variant="rectangular" width={100} height={40} />
            </Paper>
          </Box>
        </Box>
  
        {/* Bottom Content */}
        <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: 3, marginTop: '30px' }}>
          {/* Left Column */}
          <Box sx={{ flex: 1 }}>
            {/* Specifications */}
            <Paper sx={{ p: 3, mb: 3 }}>
              <Skeleton variant="text" height={30} width="30%" sx={{ mb: 2 }} />
              <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))', gap: 2 }}>
                {[...Array(6)].map((_, index) => (
                  <Skeleton key={index} variant="rectangular" height={50} />
                ))}
              </Box>
            </Paper>
          </Box>
  
          {/* Right Column */}
          <Box sx={{ flex: 1 }}>
            {/* Additional Info */}
            <Paper sx={{ p: 3, mb: 3 }}>
              <Skeleton variant="text" height={30} width="30%" sx={{ mb: 2 }} />
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {[...Array(6)].map((_, index) => (
                  <Skeleton key={index} variant="rectangular" width={100} height={30} />
                ))}
              </Box>
            </Paper>
  
            {/* Description */}
            <Paper sx={{ p: 3 }}>
              <Skeleton variant="text" height={30} width="30%" sx={{ mb: 2 }} />
              <Skeleton variant="text" height={20} width="100%" sx={{ mb: 1 }} />
              <Skeleton variant="text" height={20} width="90%" sx={{ mb: 1 }} />
              <Skeleton variant="text" height={20} width="95%" />
            </Paper>
          </Box>
        </Box>
      </Box>
    );
  }