import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as React from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import CustomTabPanel from "../../components/common/CustomTabPanel";
import TextEditor from "../../components/common/TextEditor";
import Grid from "@mui/material/Grid2";
import { useLocation } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { setPayment } from "../../store/paymentPlanSlice";
import usePaymentPlans from "../../hooks/usePaymentPlans";
import { ArrowBack } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

function PaymentPlanItemEdit() {
  const { id, itemId } = useParams();
  const { t } = useTranslation();
  const location = useLocation();
  const item = location.state?.item;
  const { getPaymentPlanById, paymentPlan, loading } = usePaymentPlans();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const updatedPath = location.pathname.includes("/item")
    ? location.pathname.slice(0, location.pathname.indexOf("/item"))
    : location.pathname;
  const [value, setValue] = React.useState(0);
  const [data, setData] = React.useState({
    validityDays: 15,
    isEnabled: true,
    isStandardAd: false,
    isDiscountEnabled: false,
    isCategoryFeaturedAd: false,
    isHomePageFeaturedAd: false,
    isMainHomePageAd: false,
    eligibilityTimeFrameEnabledFor: "ALL_USERS",
  });
  const theme = useTheme();

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const handleSave = () => {
    if (!data?.description?.en) {
      alert("Description (English) is required.");
      return;
    }

    if (!data?.price) {
      alert("Price is required.");
      return;
    }

    const updatedPaymentPlan = {
      ...paymentPlan,
      items: data?._id
        ? paymentPlan?.items.map((itm) =>
            itm._id === data._id ? { ...itm, ...data } : itm
          )
        : [
            ...(paymentPlan?.items || []),
            { ...data, _id: `temp_${Date.now()}` },
          ],
    };

    dispatch(setPayment({ data: updatedPaymentPlan }));

    setData({});
    navigate(updatedPath, { state: { updated: true } });
  };
  React.useEffect(() => {
    if (id && itemId && !item) {
      getPaymentPlanById(id);
    }
  }, [id]);
  React.useEffect(() => {
    if (itemId && paymentPlan?.items?.length) {
      const item = paymentPlan?.items?.find((itm) => itm._id === itemId);
      if (item) {
        setData(item);
      }
    } else if (item) {
      setData(item);
    }
  }, [paymentPlan]);
  return (
    <Box sx={{ width: "100%" }}>
      <Paper
        elevation={0}
        sx={{
          borderRadius: "0px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
            alignItems: "center",
            gap: 2,
            width: "100%",
          }}
        >
          <Box
            sx={{
              flex: 1,
              padding: 2,
              maxWidth: { xs: "100%", md: "33%" },
            }}
          >
            <Button
              startIcon={
                <ArrowBack
                  style={{
                    transform:
                      theme.direction === "rtl" ? "rotate(180deg)" : "none",
                    transition: "transform 0.3s ease",
                  }}
                />
              }
              sx={{
                fontWeight: "bold",
                color: "#F06B5B",
                textTransform: "inherit",
                "& .MuiButton-startIcon": {
                  marginLeft: theme.direction === "rtl" ? "8px" : "0px",
                  marginRight: theme.direction === "ltr" ? "8px" : "0px",
                },
              }}
              onClick={() =>
                navigate(updatedPath, { state: { updated: true } })
              }
            >
              {t("BACK_TO_PAYMENT_PLAN")}
            </Button>
          </Box>
          <Box
            sx={{
              flex: 1,
              maxWidth: { xs: "100%", md: "33%" },
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h5"
              fontWeight="bold"
              sx={{ ml: 2, color: "#F06B5B" }}
            >
              {paymentPlan.type === "PROMOTIONAL_PAYMENT_OPTION"
                ? t("ADD_PROMOTIONAL_PLAN_ITEM_HEADER")
                : t("ADD_PAYMENT_PLAN_ITEM_HEADER")}
            </Typography>
          </Box>

          <Box
            sx={{
              flex: 1,
              padding: 2,
              maxWidth: { xs: "100%", md: "33%" },
              textAlign: "center",
            }}
          ></Box>
        </Box>
      </Paper>
      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", padding: "20px", mt: "20px" }}
      >
        <Container maxWidth="sm">
          <Box
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
            pb={3}
          >
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  value="Enable"
                  control={
                    <Checkbox
                      checked={data?.isEnabled === true}
                      onChange={() => setData({ ...data, isEnabled: true })}
                    />
                  }
                  sx={{
                    "& .MuiFormControlLabel-label": { fontWeight: "bold" },
                  }}
                  label={t("ENABLE")}
                  labelPlacement="End"
                />
                <FormControlLabel
                  value="Dissable"
                  control={
                    <Checkbox
                      checked={data?.isEnabled === false}
                      onChange={() => setData({ ...data, isEnabled: false })}
                    />
                  }
                  sx={{
                    "& .MuiFormControlLabel-label": { fontWeight: "bold" },
                  }}
                  label={t("DISABLE")}
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl>
          </Box>
          <Typography
            textAlign="center"
            variant="body2"
            color="primary.main"
            marginBottom="1rem"
            fontWeight="bold"
          >
            {paymentPlan.type === "PROMOTIONAL_PAYMENT_OPTION"
              ? t("PROMOTIONAL_PLAN_DESCRIPTION")
              : t("PAYMENT_PLAN_DESCRIPTION")}
          </Typography>
        </Container>
        <Container maxWidth="sm">
          <Box style={{ display: "flex", width: "100%" }}>
            <Button
              variant={value === 0 ? "contained" : "text"}
              style={{
                borderRadius: "0px",
                width: "50%",
                fontSize: "20px",
                fontWeight: "bold",
              }}
              onClick={() => handleChange(0)}
            >
              {t("ENGLISH")}
            </Button>
            <Button
              variant={value === 1 ? "contained" : "text"}
              style={{
                borderRadius: "0px",
                width: "50%",
                fontSize: "20px",
                fontWeight: "bold",
              }}
              onClick={() => handleChange(1)}
            >
              {t("ARABIC")}
            </Button>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <TextEditor
              value={data?.description?.en}
              onChange={(content) =>
                setData({
                  ...data,
                  description: {
                    ar: data?.description?.ar,
                    en: content,
                  },
                })
              }
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <TextEditor
              value={data?.description?.ar}
              onChange={(content) =>
                setData({
                  ...data,
                  description: {
                    en: data.description.en,
                    ar: content,
                  },
                })
              }
              language={"ar"}
            />
          </CustomTabPanel>
        </Container>
      </Paper>

      {paymentPlan.type === "PROMOTIONAL_PAYMENT_OPTION" && (
        <Paper
          elevation={0}
          sx={{ borderRadius: "0px", padding: "20px", mt: "20px" }}
        >
          <Container maxWidth="md">
            <Box display="flex" justifyContent="center">
              <Typography color="primary" fontWeight="bold">
                {t("ELIGIBILITY_TIME_FRAME")}
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-around",
                marginTop: "2rem",
              }}
              pb={3}
            >
              <FormControl fullWidth>
                <RadioGroup
                  fullWidth
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <Grid
                    width={"100%"}
                    display="flex"
                    justifyContent="space-around"
                  >
                    <Grid md={12}>
                      <FormControlLabel
                        value="female"
                        control={
                          <Radio
                            checked={
                              data?.eligibilityTimeFrameEnabledFor ===
                              "FIRST_TIME_USERS"
                            }
                            onChange={() =>
                              setData({
                                ...data,
                                eligibilityTimeFrameEnabledFor:
                                  "FIRST_TIME_USERS",
                              })
                            }
                          />
                        }
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            fontWeight: "bold",
                          },
                        }}
                        label={t("ENABLE_FOR_FIRST_TIME_USER_ONLY")}
                      />
                      <Grid size={6} mt={2}>
                        <Typography variant="body1" fontWeight="bold">
                          {t("ENTER_VALIDITY")}
                        </Typography>
                        <TextField
                          fullWidth
                          size="small"
                          type="number"
                          sx={{ marginTop: ".5rem" }}
                          inputProps={{
                            min: 0,
                          }}
                          value={data?.validityDays || ""}
                          onChange={(event) =>
                            setData({
                              ...data,
                              validityDays: event.target.value,
                            })
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid md={12}>
                      <FormControlLabel
                        value="other"
                        control={
                          <Radio
                            checked={
                              data?.eligibilityTimeFrameEnabledFor ===
                              "ALL_USERS"
                            }
                            onChange={() =>
                              setData({
                                ...data,
                                eligibilityTimeFrameEnabledFor: "ALL_USERS",
                              })
                            }
                          />
                        }
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            fontWeight: "bold",
                          },
                        }}
                        label={t("ENABLE_FOR_ALL_USERS")}
                      />
                      <Grid size={6} mt={2}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <Box
                            display="flex"
                            alignItems="center"
                            marginBottom="1rem"
                          >
                            <Typography
                              sx={{
                                marginRight:
                                  i18n.language === "en" ? ".6rem" : "0",
                                marginLeft:
                                  i18n.language === "ar" ? "1.2rem" : "0",
                                fontWeight: "bold",
                              }}
                            >
                              {t("START_DATE")}
                            </Typography>
                            <DatePicker
                              format="dd/MM/yyyy"
                              disablePast
                              value={
                                data?.eligibilityTimeFrameStartDate
                                  ? new Date(data.eligibilityTimeFrameStartDate)
                                  : null
                              }
                              minDate={new Date()} // Restrict to future dates
                              maxDate={
                                data?.eligibilityTimeFrameEndDate
                                  ? new Date(data.eligibilityTimeFrameEndDate)
                                  : null
                              }
                              onChange={(newValue) => {
                                if (newValue) {
                                  const startOfDay = new Date(newValue);
                                  startOfDay.setHours(0, 0, 0, 0); // Set to start of the day
                                  setData((prevData) => ({
                                    ...prevData,
                                    eligibilityTimeFrameStartDate:
                                      startOfDay.toISOString(),
                                  }));
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  size="small"
                                  {...params}
                                  fullWidth
                                  inputProps={{
                                    ...params.inputProps,
                                    readOnly: true, // Disables manual input
                                  }}
                                />
                              )}
                            />
                          </Box>
                          <Box display="flex" alignItems="center">
                            <Typography
                              sx={{
                                marginRight:
                                  i18n.language === "en" ? "1rem" : "0",
                                marginLeft:
                                  i18n.language === "ar" ? ".3rem" : "0",
                                fontWeight: "bold",
                              }}
                            >
                              {t("END_DATE")}
                            </Typography>
                            <DatePicker
                              format="dd/MM/yyyy"
                              disablePast
                              value={
                                data?.eligibilityTimeFrameEndDate
                                  ? new Date(data.eligibilityTimeFrameEndDate)
                                  : null
                              }
                              minDate={
                                data?.eligibilityTimeFrameStartDate
                                  ? new Date(data.eligibilityTimeFrameStartDate)
                                  : new Date()
                              }
                              onChange={(newValue) => {
                                if (newValue) {
                                  const endOfDay = new Date(newValue);
                                  endOfDay.setHours(23, 59, 59, 999); // Set to end of the day
                                  setData((prevData) => ({
                                    ...prevData,
                                    eligibilityTimeFrameEndDate:
                                      endOfDay.toISOString(),
                                  }));
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  size="small"
                                  {...params}
                                  fullWidth
                                  inputProps={{
                                    ...params.inputProps,
                                    readOnly: true, // Disables manual input
                                  }}
                                />
                              )}
                            />
                          </Box>
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  </Grid>
                </RadioGroup>
              </FormControl>
            </Box>
          </Container>
        </Paper>
      )}

      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", padding: "20px", mt: "20px" }}
      >
        <Container maxWidth="sm">
          <Box
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              gap: "3rem",
            }}
            pb={3}
          >
            <Grid container spacing={2} alignItems="center">
              {paymentPlan.type === "REGULAR_PAYMENT_PLAN" && (
                <Grid size={12}>
                  <Typography variant="body1" fontWeight="bold">
                    {t("ENTER_VALIDITY")}
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    type="number"
                    value={data?.validityDays || ""}
                    inputProps={{
                      min: 0,
                    }}
                    onChange={(event) =>
                      setData({
                        ...data,
                        validityDays: event.target.value,
                      })
                    }
                  />
                </Grid>
              )}
              <Grid size={12}>
                <Typography variant="body1" fontWeight="bold">
                  {t("ENTER_PRICE")}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  type="number"
                  value={data?.price}
                  inputProps={{
                    min: 0,
                  }}
                  onChange={(event) => {
                    setData({ ...data, price: event.target.value });
                  }}
                />
              </Grid>
            </Grid>
            {paymentPlan.type === "REGULAR_PAYMENT_PLAN" && (
              <Grid
                container
                spacing={2}
                alignItems="center"
                marginTop="1.5rem"
              >
                <Grid size={12} display="flex">
                  <FormControlLabel
                    value="Dissable"
                    control={
                      <Checkbox
                        checked={data?.isDiscountEnabled || false}
                        onChange={(e) =>
                          setData({
                            ...data,
                            isDiscountEnabled: e.target.checked,
                          })
                        }
                      />
                    }
                    sx={{
                      "& .MuiFormControlLabel-label": { fontWeight: "bold" },
                    }}
                    label={t("ENABLE_DISCOUNT")}
                    labelPlacement="end"
                  />
                </Grid>
                <Grid size={12}>
                  <Typography variant="body1" fontWeight="bold">
                    {t("DISCOUNT_PRICE")}
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    type="number"
                    disabled={!data?.isDiscountEnabled}
                    inputProps={{
                      min: 0,
                    }}
                    value={data?.discountPrice}
                    onChange={(event) =>
                      setData({ ...data, discountPrice: event.target.value })
                    }
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        </Container>
      </Paper>
      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", padding: "20px", mt: "20px" }}
      >
        <Container maxWidth="sm">
          <Box
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
            pb={3}
          >
            <FormControl component="fieldset">
              <FormGroup aria-label="position">
                <FormControlLabel
                  value="Standard Ads"
                  control={
                    <Checkbox
                      checked={data?.isStandardAd || false}
                      onChange={(e) =>
                        setData({ ...data, isStandardAd: e.target.checked })
                      }
                    />
                  }
                  sx={{
                    "& .MuiFormControlLabel-label": { fontWeight: "bold" },
                  }}
                  label={t("ENABLE_AS_STANDARD_ADS")}
                  labelPlacement="End"
                />
                <FormControlLabel
                  value="Dissable"
                  control={
                    <Checkbox
                      checked={data?.isCategoryFeaturedAd || false}
                      onChange={(e) =>
                        setData({
                          ...data,
                          isCategoryFeaturedAd: e.target.checked,
                        })
                      }
                    />
                  }
                  sx={{
                    "& .MuiFormControlLabel-label": { fontWeight: "bold" },
                  }}
                  label={t("ENABLE_AS_CATEGORY_FEATURED_ADS")}
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="Dissable"
                  control={
                    <Checkbox
                      checked={data?.isHomePageFeaturedAd || false}
                      onChange={(e) =>
                        setData({
                          ...data,
                          isHomePageFeaturedAd: e.target.checked,
                        })
                      }
                    />
                  }
                  sx={{
                    "& .MuiFormControlLabel-label": { fontWeight: "bold" },
                  }}
                  label={t("ENABLE_AS_HOME_FEATURED_ADS")}
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="Dissable"
                  control={
                    <Checkbox
                      checked={data?.isMainHomePageAd || false}
                      onChange={(e) =>
                        setData({
                          ...data,
                          isMainHomePageAd: e.target.checked,
                        })
                      }
                    />
                  }
                  sx={{
                    "& .MuiFormControlLabel-label": { fontWeight: "bold" },
                  }}
                  label={t("ENABLE_AS_MAIN_HOME_PAGE_ADS")}
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl>
          </Box>
        </Container>
      </Paper>

      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "30px" }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{
                mx: "5px",
                textTransform: "inherit",
                minWidth: "150px",
                fontWeight: "bold",
              }}
              size="large"
              onClick={() => handleSave()}
            >
              {t("SAVE")}
            </Button>
          </Box>
        </Container>
      </Paper>
    </Box>
  );
}

export default PaymentPlanItemEdit;
