import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import PaginationSection from "./PaginationSection";

const CustomTable = ({
  data,
  columns,
  loading,
  pagination,
  setPagination,
  hidePagination,
}) => {
  const { t } = useTranslation();
  const handleChangePage = (event, newPage) => {
    setPagination({
      ...pagination,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setPagination({
      ...pagination,
      page: 1,
      limit: parseInt(event.target.value, 10),
    });
  };

  return (
    <Box sx={{ width: "100%", marginTop: 3 }}>
      <Table stickyHeader aria-label="custom table" sx={{ minWidth: 750 }}>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                sx={{
                  padding: "5px",
                  color: "white",
                  fontWeight: "bold",
                  backgroundColor: "primary.main",
                }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {!loading && (
          <TableBody>
            {data?.length > 0 ? (
              data.map((row, index) => (
                <TableRow hover key={index}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell
                        sx={{ padding: "0px", fontWeight: "bold" }}
                        key={column.id}
                        align={column.align}
                      >
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  align="center"
                  sx={{ fontWeight: "bold" }}
                >
                  {t("NO_DATA_FOUND")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        )}
      </Table>

      {loading && (
        <div
          style={{
            minHeight: "250px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={24} />
        </div>
      )}

      {/* Pagination Controls */}
      {!hidePagination && (
        <Box p={2}>
          <PaginationSection
            loading={loading}
            totalItems={pagination?.totalCount}
            itemsPerPage={pagination?.limit || 10}
            currentPage={pagination?.page}
            totalPages={pagination?.totalPages}
            onPageChange={handleChangePage}
            onItemsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      )}
    </Box>
  );
};

export default CustomTable;
