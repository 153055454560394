import {
  Box,
  Button,
  Checkbox,
  Container,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomTable from "../../components/common/CustomTable";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useNavigate } from "react-router-dom";
import { formatTimestamp } from "../../utils/formatHelper";
import useAd from "../../hooks/useAd";
import getLocalizedValue from "../../utils/getLocalizedValue";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import i18n from "i18next";

function Ads() {
  const navigate = useNavigate();
  const { getAdList, adList, deleteAd, loading } = useAd();
  const [search, setSearch] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const [pagination, setPagination] = useState({
    page: adList?.page || 1,
    limit: adList?.limit || 10,
  });
  const [pageData, setPageData] = useState({
    totalCount: adList?.totalCount || 0,
    totalPages: adList?.totalPages || 0,
  });
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({
    startDate: "",
    endDate: "",
  });
  const { t } = useTranslation();
  const columns = [
    { id: "_id", label: "", align: i18n.language == "en" ? "left" : "right" },
    {
      id: "ad_title",
      label: t("AD_TITLE"),
      align: i18n.language == "en" ? "left" : "right",
    },
    { id: "id", label: t("ID"), align: "left" },
    { id: "user", label: t("USER"), align: "left" },
    { id: "status", label: t("STATUS"), align: "center" },
    { id: "time_frame", label: t("TIME_FRAME"), align: "left" },
    { id: "category", label: t("CATEGORY"), align: "center" },
    { id: "action", label: t("ACTION"), align: "center" },
  ];

  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) =>
      prevSelectedIds.includes(id)
        ? prevSelectedIds.filter((selectedId) => selectedId !== id)
        : [...prevSelectedIds, id]
    );
  };
  const handleDelete = () => {
    if (selectedIds.length === 0) {
      alert("Please select at least one user to delete.");
      return;
    }
    const message =
      selectedIds.length > 1
        ? "Are you sure you want to delete these ads?"
        : "Are you sure you want to delete this ad?";
    if (window.confirm(message)) {
      deleteAd({ ids: [...selectedIds] })
        .then((res) => {
          if (res.success) {
            alert("Ads deleted successfully!");
            setSelectedIds([]);
            const payload = {
              page: pagination?.page,
              limit: pagination?.limit,
              search: search,
              filter:
                (filter.startDate || filter?.endDate) && JSON.stringify(filter),
            };
            getAdList(payload);
          } else {
            alert(res.message || "Something went wrong");
          }
        })
        .catch((err) => {
          alert(`Error deleting ads: ${err.message || "Something went wrong"}`);
        });
    }
  };

  const handleSearch = () => {
    setPagination({ ...pagination, page: 1 });
    const payload = {
      page: 1,
      limit: pagination?.limit,
      search: search,
      filter: (filter.startDate || filter?.endDate) && JSON.stringify(filter),
    };
    getAdList(payload);
  };

  useEffect(() => {
    const payload = {
      page: pagination?.page,
      limit: pagination?.limit,
      search: search,
      filter: (filter.startDate || filter?.endDate) && JSON.stringify(filter),
    };
    getAdList(payload);
  }, [pagination?.page, pagination?.limit, filter]);
  useEffect(() => {
    if (adList?.ads?.length > 0) {
      const items = adList?.ads;
      setData(
        items?.map((item) => ({
          _id: (
            <Checkbox
              checked={selectedIds.includes(item._id)}
              onChange={() => handleCheckboxChange(item._id)}
            />
          ),
          ad_title: item.title,
          id: item.soldId,
          user: (
            <span
              style={{
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => navigate(`/user/${item.postedBy?._id}`)}
            >
              {item.postedBy?.name}
            </span>
          ),
          status: (
            <span
              style={{
                fontWeight: "bold",
                color:
                  item?.status === "Active"
                    ? "green"
                    : item?.status == "Pending"
                    ? "red"
                    : "orange",
              }}
            >
              {item.status}
            </span>
          ),
          time_frame: (
            <Box>
              <div>
                <span style={{ color: "#80808091" }}>{t("START")}: </span>
                {formatTimestamp(
                  item?.advertisementTimeFrame?.startDate,
                  false
                )}{" "}
              </div>
              <div>
                <span style={{ color: "#80808091" }}>{t("END")}: </span>
                {formatTimestamp(item?.advertisementTimeFrame?.endDate, false)}
              </div>
            </Box>
          ),
          category: getLocalizedValue(item.category.name),
          action: (
            <Box sx={{ display: "flex", justifyContent: "center", gap: "5px" }}>
              <Button
                size="small"
                variant="outlined"
                color="black"
                sx={{
                  textTransform: "inherit",
                  padding: "0",
                  minWidth: "65px",
                  fontWeight: "bold",
                }}
                onClick={() => {
                  navigate(`/ad-info/${item?._id}`);
                }}
              >
                {t("ADD_INFO")}
              </Button>
              <Button
                size="small"
                variant="outlined"
                color="black"
                sx={{
                  textTransform: "inherit",
                  padding: "0",
                  minWidth: "45px",
                  fontWeight: "bold",
                }}
                onClick={() => {
                  window.location.assign(`/ads/${item?._id}`);
                }}
              >
                {t("EDIT")}
              </Button>
            </Box>
          ),
        }))
      );
    } else {
      setData([]);
    }
    setPageData({
      totalCount: adList?.totalCount || 0,
      totalPages: adList?.totalPages || 0,
    });
  }, [adList, handleCheckboxChange]);
  return (
    <>
      <Paper elevation={0} sx={{ borderRadius: "0px", padding: "10px" }}>
        <Typography
          variant="h4"
          fontWeight="bold"
          textAlign="center"
          color="primary"
        >
          {t("AD_MANAGEMENT")}
        </Typography>
      </Paper>
      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "20px" }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{
                mx: "5px",
                fontWeight: "bold",
                textTransform: "inherit",
                minWidth: "150px",
              }}
              size="large"
              onClick={() => navigate("/ads/create")}
            >
              {t("CREATE_ADVERTISEMENT")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              sx={{
                mx: "5px",
                textTransform: "inherit",
                fontWeight: "bold",
                minWidth: "150px",
              }}
              size="large"
              onClick={() => handleDelete()}
            >
              {t("DELETE_ADVERTISEMENT")}
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: "20px",
            }}
          >
            <TextField
              fullWidth
              sx={{ mx: "5px" }}
              size="small"
              value={search}
              onChange={(e) => {
                e.preventDefault();
                setSearch(e.target.value);
              }}
              placeholder="Search Ad Title"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />

            <Button
              variant="contained"
              color="primary"
              sx={{
                mx: "5px",
                textTransform: "inherit",
                minWidth: "150px",
                fontWeight: "bold",
              }}
              size="medium"
              onClick={handleSearch}
            >
              {t("SEARCH")}
            </Button>
          </Box>
        </Container>
        <Container maxWidth="md">
          <DateFilter filter={filter} setFilter={setFilter} />
        </Container>
      </Paper>
      <Paper elevation={0} sx={{ borderRadius: "0px", mt: "20px" }}>
        <CustomTable
          data={data}
          columns={columns}
          loading={loading}
          pagination={{ ...pagination, ...pageData }}
          setPagination={setPagination}
        />
      </Paper>
    </>
  );
}

function DateFilter({ setFilter }) {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const { t } = useTranslation();
  const handleFilter = () => {
    const startDate = new Date(fromDate);
    const endDate = new Date(toDate);

    if (fromDate && toDate) {
      if (endDate < startDate) {
        alert("End date must be greater than start date.");
        return;
      }
    }

    const payload = {
      startDate: fromDate ? format(new Date(startDate), "yyyy-MM-dd") : null,
      endDate: toDate ? format(new Date(endDate), "yyyy-MM-dd") : null,
    };

    setFilter(payload);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        alignItems="center"
        justifyContent="center"
        gap={2}
        p={2}
        sx={{
          margin: "auto",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          <Typography variant="body2" fontWeight="bold">
            {t("START_DATE")}
          </Typography>
          <DatePicker
            slotProps={{
              actionBar: {
                actions: ["clear"],
              },
              textField: {
                sx: {
                  "& .MuiInputBase-input": {
                    padding: "6px",
                  },
                },
              },
            }}
            value={fromDate}
            onChange={(newValue) => setFromDate(newValue)}
            format="dd/MM/yyyy"
            renderInput={(params) => <TextField size="small" {...params} fullWidth />}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          <Typography variant="body2" fontWeight="bold">
            {t("END_DATE")}
          </Typography>
          <DatePicker
            slotProps={{
              actionBar: {
                actions: ["clear"],
              },
              textField: {
                sx: {
                  "& .MuiInputBase-input": {
                    padding: "6px",
                  },
                },
              },
            }}
            value={toDate}
            onChange={(newValue) => setToDate(newValue)}
            format="dd/MM/yyyy"
            renderInput={(params) => (
              <TextField size="small" {...params} fullWidth />
            )}
          />
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleFilter}
          fullWidth
          sx={{
            maxWidth: { sm: 120 },
            fontWeight: "bold",
            textTransform: "none",
          }}
        >
          {t("FILTER")}
        </Button>
      </Box>
    </LocalizationProvider>
  );
}

export default Ads;
