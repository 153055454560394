import {
  Box,
  Button,
  Checkbox,
  Container,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import CustomTable from "../../components/common/CustomTable";
import { useNavigate } from "react-router-dom";
import useUser from "../../hooks/useUser";
import getLocalizedValue from "../../utils/getLocalizedValue";
import { formatTimestamp } from "../../utils/formatHelper";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { format } from "date-fns";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import i18n from "i18next";

function UsersList() {
  const { t } = useTranslation();
  const columns = [
    { id: "_id", label: "", align: i18n.language == "en" ? "left" : "right" },
    {
      id: "name",
      label: t("NAME"),
      align: i18n.language == "en" ? "left" : "right",
    },
    { id: "joinedDate", label: t("JOINED_DATE"), align: "left" },
    { id: "status", label: t("STATUS"), align: "center" },
    { id: "totalAds", label: t("TOTAL_ADS"), align: "center" },
    { id: "action", label: t("ACTION"), align: "center" },
  ];

  const navigate = useNavigate();
  const { getUserList, users, deleteUsers, loading } = useUser();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    page: users?.page || 1,
    limit: users?.limit || 10,
  });
  const [pageData, setPageData] = useState({
    totalCount: users?.totalCount || 0,
    totalPages: users?.totalPages || 0,
  });
  const [selectedIds, setSelectedIds] = useState([]);
  const [filter, setFilter] = useState({
    startDate: "",
    endDate: "",
  });

  const handleCheckboxChange = (id) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((item) => item !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const handleSearch = () => {
    setPagination({ ...pagination, page: 1 });
    const payload = {
      page: 1,
      limit: pagination?.limit,
      search: search,
      filter: (filter.startDate || filter?.endDate) && JSON.stringify(filter),
    };
    getUserList(payload);
  };

  const handleDelete = () => {
    if (selectedIds.length === 0) {
      alert("Please select at least one user to delete.");
      return;
    }
    const message =
      selectedIds.length > 1
        ? "Are you sure you want to delete these users?"
        : "Are you sure you want to delete this user?";
    if (window.confirm(message)) {
      deleteUsers({ ids: [...selectedIds] })
        .then((res) => {
          if (res.success) {
            alert("Reports deleted successfully!");
            setSelectedIds([]);
            navigate("/users");
          } else {
            alert(
              `Error deleting users: ${res.message || "Something went wrong"}`
            );
          }
        })
        .catch((err) => {
          alert(
            `Error deleting users: ${err.message || "Something went wrong"}`
          );
        });
    }
  };
  useEffect(() => {
    const payload = {
      page: pagination?.page,
      limit: pagination?.limit,
      search: search,
      filter: (filter.startDate || filter?.endDate) && JSON.stringify(filter),
    };
    getUserList(payload);
  }, [pagination?.page, pagination?.limit, filter]);
  useEffect(() => {
    if (users?.users?.length > 0) {
      const items = users?.users;
      setData(
        items?.map((item) => ({
          _id: (
            <Box sx={{ maxWidth: "15px" }}>
              <Checkbox
                checked={selectedIds.includes(item._id)}
                onChange={() => handleCheckboxChange(item._id)}
              />
            </Box>
          ),
          name: item.name,
          joinedDate: formatTimestamp(item.createdAt, false),
          status: (
            <span
              style={{
                color:
                  item.status === "active"
                    ? "green"
                    : item.status === "inactive"
                    ? "red"
                    : item.status === "not_verified"
                    ? "orange"
                    : "black",
              }}
            >
              {item.status
                ?.replace(/_/g, " ")
                .toLowerCase()
                .replace(/(^\w|\s\w)/g, (match) => match.toUpperCase())}{" "}
            </span>
          ),
          totalAds: item.totalAds,
          action: (
            <Button
              size="small"
              variant="outlined"
              color="black"
              sx={{
                textTransform: "inherit",
                padding: "0",
                minWidth: "45px",
                fontWeight: "bold",
              }}
              onClick={() => {
                navigate(`/user/${item?._id}`);
              }}
            >
              {t("VIEW")}
            </Button>
          ),
        }))
      );
    } else {
      setData([]);
    }
    setPageData({
      totalCount: users?.totalCount || 0,
      totalPages: users?.totalPages || 0,
    });
  }, [users, selectedIds]);
  return (
    <div>
      <Paper elevation={0} sx={{ borderRadius: "0px", padding: "10px" }}>
        <Typography
          variant="h4"
          fontWeight="bold"
          textAlign="center"
          color="primary"
        >
          {t("USERS_LIST")}
        </Typography>
      </Paper>
      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "20px" }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{ mx: "5px", textTransform: "inherit", minWidth: "150px", fontWeight: "bold" }}
              size="large"
              onClick={() => navigate("/users/create")}
            >
              {t("CREATE_USER")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              sx={{ mx: "5px", textTransform: "inherit", minWidth: "150px", fontWeight: "bold" }}
              size="large"
              onClick={handleDelete}
            >
              {t("DELETE")}
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: "20px",
            }}
          >
            <TextField
              fullWidth
              sx={{ mx: "5px" }}
              size="small"
              value={search}
              onChange={(e) => {
                e.preventDefault();
                setSearch(e.target.value);
              }}
              placeholder="Search name"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />

            <Button
              variant="contained"
              color="primary"
              sx={{
                mx: "5px",
                textTransform: "inherit",
                minWidth: "150px",
                fontWeight: "bold",
              }}
              size="medium"
              onClick={handleSearch}
            >
              {t("SEARCH")}
            </Button>
          </Box>
        </Container>
        <Container maxWidth="md">
          <DateFilter setFilter={setFilter} />
        </Container>
      </Paper>

      <Paper elevation={0} sx={{ borderRadius: "0px", mt: "20px" }}>
        <CustomTable
          data={data}
          columns={columns}
          loading={loading}
          pagination={{ ...pagination, ...pageData }}
          setPagination={setPagination}
        />
      </Paper>
    </div>
  );
}

function DateFilter({ setFilter }) {
  const { t } = useTranslation();
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const handleFilter = () => {
    const startDate = new Date(fromDate);
    const endDate = new Date(toDate);

    if (fromDate && toDate) {
      if (endDate < startDate) {
        alert("End date must be greater than start date.");
        return;
      }
    }

    const payload = {
      startDate: fromDate ? format(new Date(startDate), "yyyy-MM-dd") : null,
      endDate: toDate ? format(new Date(endDate), "yyyy-MM-dd") : null,
    };

    setFilter(payload);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        alignItems="center"
        justifyContent="center"
        gap={2}
        p={2}
        sx={{
          margin: "auto",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          <Typography variant="body2" fontWeight="bold">
            {t("START_DATE")}
          </Typography>
          <DatePicker
            slotProps={{
              actionBar: {
                actions: ["clear"],
              },
              textField: {
                sx: {
                  "& .MuiInputBase-input": {
                    padding: "6px",
                  },
                },
              },
            }}
            value={fromDate}
            onChange={(newValue) => setFromDate(newValue)}
            format="dd/MM/yyyy"
            renderInput={(params) => (
              <TextField size="small" {...params} fullWidth />
            )}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          <Typography variant="body2" fontWeight="bold">
            {t("END_DATE")}
          </Typography>
          <DatePicker
            slotProps={{
              actionBar: {
                actions: ["clear"],
              },
              textField: {
                sx: {
                  "& .MuiInputBase-input": {
                    padding: "6px",
                  },
                },
              },
            }}
            value={toDate}
            onChange={(newValue) => setToDate(newValue)}
            format="dd/MM/yyyy"
            renderInput={(params) => (
              <TextField size="small" {...params} fullWidth />
            )}
          />
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleFilter}
          fullWidth
          sx={{ maxWidth: { sm: 120 }, textTransform: "none", fontWeight: "bold" }}
        >
          {t("FILTER")}
        </Button>
      </Box>
    </LocalizationProvider>
  );
}

export default UsersList;
