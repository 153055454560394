import React, { lazy } from "react";
import { Navigate } from "react-router-dom";

import Wrapper from "./layouts/Wrapper";
import HomePage from "./pages/HomePage";
import CategoryPage from "./pages/category/CategoryPage";
import CategoryEditPage from "./pages/category/CategoryEditPage";
import SpecificationPage from "./pages/specification/SpecificationPage";
import SpecificationEditPage from "./pages/specification/SpecificationEditPage";
import AdditionalDetails from "./pages/AdditionalDetails/AdditionalDetails";
import ManuPage from "./pages/menu/ManuPage";
import MenuEditPage from "./pages/menu/MenuEditPage";
import EditSubCategory from "./pages/menu/EditSubCategory";
import PaymentPlansPage from "./pages/payment plan/PaymentPlansPage";
import PaymentPlansEditPage from "./pages/payment plan/PaymentPlansEditPage";
import PaymentPlanItemEdit from "./pages/payment plan/PaymentPlanItemEdit";
import AdminLogin from "./pages/Login/AdminLogin";
import PrivateRoute from "./components/common/PrivateRoute";
import MuiDashboardLayout from "./layouts/NewWrapper";
import UsersList from "./pages/users/UsersList";
import UserDetails from "./pages/users/UserDetails";
import ChatInterface from "./pages/Chat/ChatInterface";
import Reports from "./pages/reports/Reports";
import ReportView from "./pages/reports/ReportView";
import Dashboard from "./pages/dashboard/Dashboard";
import Ads from "./pages/Ad/Ads";
import CreatePost from "./pages/Ad/CreatePost";
import AdInfo from "./pages/Ad/AdInfo";
import UpdateStatus from "./pages/Ad/UpdateStatus";
import AdvertisementSettings from "./components/createpost/AdvertisementSettings";
import Settings from "./pages/Settings/Settings";
import GeneralSettings from "./pages/Settings/GeneralSettings";
import AdSettings from "./pages/Settings/AdSettings";
import AboutUs from "./pages/Settings/AboutUs";
import TermsAndCondition from "./pages/Settings/TermsAndCondition";
import PrivacyPolicy from "./pages/Settings/PrivacyPolicy";
import FAQ from "./pages/Settings/FAQ";
import AddAFAQ from "./pages/Settings/AddAFAQ";
import CreateCountry from "./pages/Settings/CreateCountry";
import AdDetails from "./pages/Ad/AdDetails";

// Routes configuration
const routes = [
  {
    element: <AdminLogin />,
    path: "/login",
  },
  {
    element: <MuiDashboardLayout />, // This will wrap all child routes with layout
    children: [
      {
        path: "/",
        element: (
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        ),
      },
      {
        path: "/category",
        element: (
          <PrivateRoute>
            <CategoryPage />
          </PrivateRoute>
        ),
      },
      {
        path: "/category/create",
        element: (
          <PrivateRoute>
            <CategoryEditPage />
          </PrivateRoute>
        ),
      },
      {
        path: "/category/:id",
        element: (
          <PrivateRoute>
            <CategoryEditPage />
          </PrivateRoute>
        ),
      },
      {
        path: "/specification",
        element: (
          <PrivateRoute>
            <SpecificationPage />
          </PrivateRoute>
        ),
      },
      {
        path: "/specification/create",
        element: (
          <PrivateRoute>
            <SpecificationEditPage />
          </PrivateRoute>
        ),
      },
      {
        path: "/specification/:id",
        element: (
          <PrivateRoute>
            <SpecificationEditPage />
          </PrivateRoute>
        ),
      },
      {
        path: "/additional-details",
        element: (
          <PrivateRoute>
            <AdditionalDetails />
          </PrivateRoute>
        ),
      },
      {
        path: "/additional-details/create",
        element: (
          <PrivateRoute>
            <SpecificationEditPage />
          </PrivateRoute>
        ),
      },
      {
        path: "/menu",
        element: (
          <PrivateRoute>
            <ManuPage />
          </PrivateRoute>
        ),
      },
      {
        path: "/menu/create",
        element: (
          <PrivateRoute>
            <MenuEditPage />
          </PrivateRoute>
        ),
      },
      {
        path: "/menu/:id",
        element: (
          <PrivateRoute>
            <MenuEditPage />
          </PrivateRoute>
        ),
      },
      {
        path: "/menu/sub/:id",
        element: (
          <PrivateRoute>
            <EditSubCategory />
          </PrivateRoute>
        ),
      },
      {
        path: "/payment-plan",
        element: (
          <PrivateRoute>
            <PaymentPlansPage />
          </PrivateRoute>
        ),
      },
      {
        path: "/payment-plan/create",
        element: (
          <PrivateRoute>
            <PaymentPlansEditPage />
          </PrivateRoute>
        ),
      },
      {
        path: "/payment-plan/create/item",
        element: (
          <PrivateRoute>
            <PaymentPlanItemEdit />
          </PrivateRoute>
        ),
      },
      {
        path: "/payment-plan/:id",
        element: (
          <PrivateRoute>
            <PaymentPlansEditPage />
          </PrivateRoute>
        ),
      },
      {
        path: "/payment-plan/:id/item/:itemId",
        element: (
          <PrivateRoute>
            <PaymentPlanItemEdit />
          </PrivateRoute>
        ),
      },
      {
        path: "/payment-plan/:id/item",
        element: (
          <PrivateRoute>
            <PaymentPlanItemEdit />
          </PrivateRoute>
        ),
      },
      {
        path: "/ads",
        element: (
          <PrivateRoute>
            <Ads />
          </PrivateRoute>
        ),
      },
      {
        path: "/ads/create",
        element: (
          <PrivateRoute>
            <CreatePost />
          </PrivateRoute>
        ),
      },
      {
        path: "/test",
        element: (
          <PrivateRoute>
            <AdvertisementSettings />
          </PrivateRoute>
        ),
      },
      {
        path: "/ads/:Id",
        element: (
          <PrivateRoute>
            <CreatePost />
          </PrivateRoute>
        ),
      },
      {
        path: "/ad-info/:Id",
        element: (
          <PrivateRoute>
            <AdInfo />
          </PrivateRoute>
        ),
      },
      {
        path: "/ad-details/:Id",
        element: (
          <PrivateRoute>
            <AdDetails />
          </PrivateRoute>
        ),
      },
      {
        path: "/update-status/:Id",
        element: (
          <PrivateRoute>
            <UpdateStatus />
          </PrivateRoute>
        ),
      },
      {
        path: "/users",
        element: (
          <PrivateRoute>
            <UsersList />
          </PrivateRoute>
        ),
      },
      {
        path: "/user/:id",
        element: (
          <PrivateRoute>
            <UserDetails />
          </PrivateRoute>
        ),
      },
      {
        path: "/payment-plan/item/create",
        element: (
          <PrivateRoute>
            <PaymentPlanItemEdit />
          </PrivateRoute>
        ),
      },
      {
        path: "/reports",
        element: (
          <PrivateRoute>
            <Reports />
          </PrivateRoute>
        ),
      },
      {
        path: "/report/:id",
        element: (
          <PrivateRoute>
            <ReportView />
          </PrivateRoute>
        ),
      },
      {
        path: "/chat",
        element: (
          <PrivateRoute>
            <ChatInterface />
          </PrivateRoute>
        ),
      },
      {
        path: "/chat/:id",
        element: (
          <PrivateRoute>
            <ChatInterface />
          </PrivateRoute>
        ),
      },
      {
        path: "/settings",
        element: (
          <PrivateRoute>
            <Settings />
          </PrivateRoute>
        ),
      },
      {
        path: "/settings/general-settings",
        element: (
          <PrivateRoute>
            <GeneralSettings />
          </PrivateRoute>
        ),
      },
      {
        path: "/settings/ad-settings",
        element: (
          <PrivateRoute>
            <AdSettings />
          </PrivateRoute>
        ),
      },
      {
        path: "/settings/country/create",
        element: (
          <PrivateRoute>
            <CreateCountry />
          </PrivateRoute>
        ),
      },
      {
        path: "/settings/about-us",
        element: (
          <PrivateRoute>
            <AboutUs />
          </PrivateRoute>
        ),
      },
      {
        path: "/settings/terms-and-condition",
        element: (
          <PrivateRoute>
            <TermsAndCondition />
          </PrivateRoute>
        ),
      },
      {
        path: "/settings/privacy-policy",
        element: (
          <PrivateRoute>
            <PrivacyPolicy />
          </PrivateRoute>
        ),
      },
      {
        path: "/settings/faq",
        element: (
          <PrivateRoute>
            <FAQ />
          </PrivateRoute>
        ),
      },
      {
        path: "/settings/faq/create",
        element: (
          <PrivateRoute>
            <AddAFAQ />
          </PrivateRoute>
        ),
      },
      {
        path: "/settings/faq/:id",
        element: (
          <PrivateRoute>
            <AddAFAQ />
          </PrivateRoute>
        ),
      },
      //     {
      //     path: '/post-app',
      //     element: <PostApp />,
      //   },
      //   {
      //     path: '/categories',
      //     element: <Categories />,
      //   },
      //   {
      //     path: '/about-us',
      //     element: <AboutUs />,
      //   },
      //   {
      //     path: '/my-account',
      //     element: (
      //       <PrivateRoute>
      //         <MyAccount />
      //       </PrivateRoute>
      //     ),
      //   },
      //   {
      //     path: '/contact-us',
      //     element: (
      //       <PrivateRoute>
      //         <ContactUs />
      //       </PrivateRoute>
      //     ),
      //   },
      // Catch-all route to handle unknown paths
      {
        path: "*",
        element: <Navigate to="/" />,
      },
    ],
  },
];

export default routes;
