import React, { useCallback } from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { BorderAllRounded } from '@mui/icons-material';

// Set the default map options to disable interactions
const mapOptions = {
  disableDefaultUI: true, // Disable all default UI
  zoomControl: false, // Disable zoom control
  draggable: false, // Disable dragging
  scrollwheel: false, // Disable scrolling
  disableDoubleClickZoom: true, // Disable double-click to zoom
};

// Set the container style for the map
const containerStyle = {
  width: '250px',
  height: '150px',
  BorderAllRounded:'5px'
};

const AdPreviewMap = ({ latitude, longitude, apiKey }) => {
  // Load the Google Maps API
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: apiKey, // Your API key
  });

  const onLoad = useCallback((map) => {
    // Center map on the given location
    map.setCenter({ lat: latitude, lng: longitude });
  }, [latitude, longitude]);

  if (!isLoaded) {
    return <div>Loading map...</div>;
  }

  return (
    <GoogleMap
    mapContainerStyle={containerStyle}
    zoom={13} // Adjust the zoom level as needed
    options={mapOptions} // Apply map options to disable interactions
    onLoad={onLoad} // Center map on load
    defaultCenter={{ lat: latitude, log: longitude  }}

    >
    {/* Add a marker at the specified latitude and longitude */}
    <Marker position={{ lat: latitude, log: longitude }} />

    </GoogleMap>
  );
};

export default AdPreviewMap;
