import React, { useEffect, useState } from "react";
import CustomPageHeader from "../../components/common/CustomPageHeader";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import useSettings from "../../hooks/useSettings";
import { useTranslation } from "react-i18next";

function FAQ() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { getFaqData, deleteFaq } = useSettings();
  const [faqItems, setFaqItems] = useState([]);
  const fetchFAQ = async () => {
    try {
      const response = await getFaqData();
      const { data } = response;
      console.log(response);
      setFaqItems(data || []);
    } catch (error) {
      console.error(error);
    }
  };
  const handleDeleteFaq = async (id) => {
    if (!window.confirm("Are you sure you want to delete this?")) {
      return;
    }
    await deleteFaq(id);
    fetchFAQ();
  };

  useEffect(() => {
    fetchFAQ();

    return () => {};
  }, []);

  return (
    <div>
      <CustomPageHeader
        heading={t("FAQ")}
        buttontext={t("BACK_TO_SETTINGS")}
        path="/settings"
      />

      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "30px" }}
      >
        <Container maxWidth="md">
          <Stack spacing={3}>
            {faqItems.map((item) => (
              <Card key={item._id} sx={{ bgcolor: "background.paper" }}>
                <CardHeader
                  subheader={item.question.en}
                  sx={{
                    // Set background color with opacity
                    backgroundColor: (theme) =>
                      `${theme.palette.primary.main}20`, // 30% opacity
                    padding: 2, // Add some padding
                    "& .MuiCardHeader-subheader": {
                      // Style subheader text
                      color: "black",
                      fontWeight: "bold",
                    },
                  }}
                  action={
                    <Box sx={{ display: "flex", gap: 1, flexShrink: 0 }}>
                      <Button
                        size="small"
                        variant="outlined"
                        color="secondary"
                        onClick={() => navigate(`/settings/faq/${item._id}`)}
                        sx={{ textTransform: "none", fontWeight: "bold" }}
                      >
                        {t("EDIT")}
                      </Button>
                      <Button
                        size="small"
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleDeleteFaq(item._id)}
                        sx={{ textTransform: "none", fontWeight: "bold" }}
                      >
                        {t("DELETE")}
                      </Button>
                    </Box>
                  }
                />
                <CardContent>
                  <Box sx={{ mb: 2 }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography
                        variant="body2"
                        fontWeight="bold"
                        sx={{ flex: 1, mr: 2 }}
                      >
                        {item.answer.en}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            ))}
          </Stack>
        </Container>
      </Paper>
      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "30px" }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              sx={{
                mx: "5px",
                textTransform: "inherit",
                minWidth: "150px",
                fontWeight: "bold",
              }}
              size="large"
              onClick={() => window.history.back()}
            >
              {t("BACK")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{
                mx: "5px",
                textTransform: "inherit",
                minWidth: "150px",
                fontWeight: "bold",
              }}
              size="large"
              onClick={() => navigate("/settings/faq/create")}
            >
              {t("ADD_A_FAQ")}
            </Button>
          </Box>
        </Container>
      </Paper>
    </div>
  );
}

export default FAQ;
