import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import CustomPageHeader from "../../components/common/CustomPageHeader";
import { useTranslation } from "react-i18next";

function Settings() {
  const { t } = useTranslation();

  return (
    <Box sx={{ p: 3 }}>
      {/* Header */}
      <CustomPageHeader
        heading={t("SETTINGS")}
        buttontext={t("BACK_TO_DASHBOARD")}
        path="/"
      />

      {/* Stats Cards */}
      <Paper style={{ marginTop: "30px" }} elevation={0}>
        <Box
          sx={{
            padding: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Link
            style={{ textDecoration: "none" }}
            to="/settings/general-settings"
          >
            <Typography style={{ fontWeight: "bold", color: "black" }}>
              {t("GENERAL_SETTINGS")}
            </Typography>
          </Link>
          <Link style={{ textDecoration: "none" }} to="/settings/ad-settings">
            <Typography style={{ fontWeight: "bold", color: "black" }}>
              {t("AD_SETTINGS")}
            </Typography>
          </Link>
          <Link style={{ textDecoration: "none" }} to="/settings/about-us">
            <Typography style={{ fontWeight: "bold", color: "black" }}>
              {t("ABOUT_US")}
            </Typography>
          </Link>
          <Link
            style={{ textDecoration: "none" }}
            to="/settings/terms-and-condition"
          >
            <Typography style={{ fontWeight: "bold", color: "black" }}>
              {t("TERMS_AND_CONDITIONS")}
            </Typography>
          </Link>
          <Link
            style={{ textDecoration: "none" }}
            to="/settings/privacy-policy"
          >
            <Typography style={{ fontWeight: "bold", color: "black" }}>
              {t("PRIVACY_POLICY")}
            </Typography>
          </Link>
          <Link style={{ textDecoration: "none" }} to="/settings/faq">
            <Typography style={{ fontWeight: "bold", color: "black" }}>
              {t("FAQS")}
            </Typography>
          </Link>
          {/* <Link style={{textDecoration:'none'}} ><Typography style={{fontWeight:'bold',color:'black'}}>{t('SETTINGS')}</Typography></Link> */}
        </Box>
      </Paper>
    </Box>
  );
}

export default Settings;
