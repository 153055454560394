import React, { useEffect, useState } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { Autocomplete, CircularProgress, Button } from "@mui/material";
import axios from "axios";
import CustomInput from "../common/CustomInput";
import { useTranslation } from "react-i18next";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const defaultCenter = {
  lat: -3.745,
  lng: -38.523,
};

const MapComponent = ({
  handleLocationChange,
  initialMarkerPosition,
  initialLocationName,
  initialAddress,
}) => {
  const { t } = useTranslation();
  const [markerPosition, setMarkerPosition] = useState(
    initialMarkerPosition || defaultCenter
  );
  const [mapCenter, setMapCenter] = useState(
    initialMarkerPosition || defaultCenter
  );
  const [locationName, setLocationName] = useState(initialLocationName || "");
  const [address, setAddress] = useState(initialAddress || {});
  const [locationOptions, setLocationOptions] = useState([]); // Location suggestions
  const [loading, setLoading] = useState(false); // Loader for autocomplete
  const [error, setError] = useState(null);

  // Fetch location suggestions from OpenStreetMap Nominatim API
  const fetchLocationSuggestions = async (input) => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/search?q=${input}&format=json&addressdetails=1&limit=5`
      );
      const suggestions = response.data.map((item) => ({
        label: item.display_name,
        latitude: item.lat,
        longitude: item.lon,
        address: item.address,
      }));
      setLocationOptions(suggestions);
    } catch (err) {
      setError("Unable to fetch location suggestions.");
    }

    setLoading(false);
  };

  // Fetch user's current location and reverse geocode it
  const fetchMyLocation = async () => {
    setLoading(true);
    setError(null);
    setLocationName(null);
    setAddress({});

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          setMarkerPosition({ lat: latitude, lng: longitude });
          setMapCenter({ lat: latitude, lng: longitude });

          // Reverse geocoding request to OpenStreetMap Nominatim API
          try {
            const response = await axios.get(
              `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
            );

            const {
              country,
              country_code,
              county,
              postcode,
              state,
              state_district,
              town,
            } = response.data.address;
            setAddress({
              country,
              country_code,
              county,
              postcode,
              state,
              state_district,
              town,
            });
            setLocationName(response.data.display_name);
          } catch (geoError) {
            setError("Unable to retrieve location name.");
          }

          setLoading(false);
        },
        (geoError) => {
          setError("Unable to retrieve your location.");
          setLoading(false);
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
      setLoading(false);
    }
  };

  // Handle location selection from autocomplete suggestions
  const handleLocationSelect = (event, newValue) => {
    if (newValue) {
      const { latitude, longitude } = newValue;
      setMarkerPosition({
        lat: parseFloat(latitude),
        lng: parseFloat(longitude),
      });
      setMapCenter({ lat: parseFloat(latitude), lng: parseFloat(longitude) });
      setLocationName(newValue.label);

      const {
        country,
        country_code,
        county,
        postcode,
        state,
        state_district,
        town,
      } = newValue.address;
      setAddress({
        country,
        country_code,
        county,
        postcode,
        state,
        state_district,
        town,
      });
    }
  };

  useEffect(() => {
    handleLocationChange({
      locationName,
      markerPosition,
      address,
    });
  }, [locationName, markerPosition, address]);

  return (
    <>
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}
      >
        {/* Autocomplete for location suggestions */}
        <Autocomplete
          freeSolo
          fullWidth
          options={locationOptions}
          getOptionLabel={(option) => option.label}
          loading={loading}
          onInputChange={(event, inputValue) => {
            if (inputValue) {
              fetchLocationSuggestions(inputValue);
            }
          }}
          onChange={handleLocationSelect}
          renderInput={(params) => (
            <CustomInput
              {...params}
              variant="outlined"
              fullWidth
              value={locationName}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              style={{ flexGrow: "1" }}
            />
          )}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={fetchMyLocation}
          size="large"
          fontWeight="bold"
          style={{ marginLeft: "10px", minWidth: "150px" }}
        >
          {t("MY_LOCATION")}
        </Button>
      </div>

      {error && <p style={{ color: "red" }}>{error}</p>}
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={mapCenter}
        zoom={12}
        onClick={(event) => {
          const lat = event.latLng.lat();
          const lng = event.latLng.lng();
          setMarkerPosition({ lat, lng });

          // Reverse geocode on map click
          axios
            .get(
              `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json`
            )
            .then((response) => {
              setLocationName(response.data.display_name);
              const {
                country,
                country_code,
                county,
                postcode,
                state,
                state_district,
                town,
              } = response.data.address;
              setAddress({
                country,
                country_code,
                county,
                postcode,
                state,
                state_district,
                town,
              });
            })
            .catch(() => {
              setError("Unable to retrieve location name.");
            });
        }}
      >
        {markerPosition && <Marker position={markerPosition} />}
      </GoogleMap>
    </>
  );
};

export default MapComponent;
