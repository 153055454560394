import React, { useState } from 'react';
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Select,
    MenuItem,
    TextField,
    Typography,
    Paper,
    Stack,
    IconButton,
    InputAdornment,
} from '@mui/material';
import { ArrowBack, CalendarMonth } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CustomPageHeader from '../../components/common/CustomPageHeader';

export default function UpdateStatus() {
    const [status, setStatus] = useState('live');
    const [timeFrameType, setTimeFrameType] = useState('userNumber');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [days, setDays] = useState('');

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={{ mx: 'auto', p: 3 }}>
                {/* Header */}
                <CustomPageHeader heading='Update Status' buttontext=' back to Ad Info' path='/ads' />


                <Stack spacing={4} style={{marginTop:'20px'}}>
                    {/* Status Selection */}
                    <Paper elevation={0} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 3 }}>
                        <FormControl sx={{ minWidth: 200, mb: 2 }}>
                            <Select
                                value={status}
                                size='small'
                                onChange={(e) => setStatus(e.target.value)}
                                sx={{
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#F06B5B',
                                    },
                                }}
                            >
                                <MenuItem value="live">Live</MenuItem>
                                <MenuItem value="pending">Pending</MenuItem>
                                <MenuItem value="expired">Expired</MenuItem>
                            </Select>
                        </FormControl>
                        <Button
                            variant="contained"
                            sx={{
                                bgcolor: '#F06B5B',
                                '&:hover': { bgcolor: '#e05a4a' },
                                width: 200,
                            }}
                        >
                            Update
                        </Button>
                    </Paper>

                    {/* Re-Post/Extend Ad Section */}
                    <Paper elevation={0} sx={{ p: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                        <Typography variant="h6" sx={{ mb: 2, color: '#F06B5B', fontWeight: 'bold' }}>
                            <FormControlLabel
                                control={<Checkbox />}
                            /> Re-Post/Extend Ad
                        </Typography>
                        <Stack spacing={1}>
                            <FormControlLabel
                                control={<Checkbox />}
                                label="Enable as Standard Ad"
                            />
                            <FormControlLabel
                                control={<Checkbox />}
                                label="Enable as Category Featured Ad"
                            />
                            <FormControlLabel
                                control={<Checkbox />}
                                label="Enable as Nationwide Featured Ad"
                            />
                            <FormControlLabel
                                control={<Checkbox />}
                                label="Enable as Main Home Page Ad"
                            />
                        </Stack>

                        <Typography sx={{ mt: 3, mb: 2, color: 'primary.main', fontWeight: 'bold' }}>
                            Select Advertisement Time Frame
                        </Typography>

                        <RadioGroup
                            value={timeFrameType}
                            onChange={(e) => setTimeFrameType(e.target.value)}
                        >
                            <Box spacing={3}>
                                <FormControlLabel
                                    value="userNumber"
                                    control={<Radio />}
                                    label="User Number of Days"
                                />
                                <FormControlLabel
                                    value="extendedNumber"
                                    control={<Radio />}
                                    label="Extended Number of Days"
                                />
                            </Box>
                        </RadioGroup>


                        <Box sx={{ display: 'flex', justifyContent: 'space-between',mt:3 }} gap={3}>
                            <Box>
                                <Typography sx={{ mb: '0px' }}>Enter Validity (days)</Typography>
                                <TextField
                                    fullWidth
                                    value={days}
                                    onChange={(e) => setDays(e.target.value)}
                                    type="number"
                                />
                            </Box>
                            <Stack >
                                <Box>
                                    <Typography sx={{ mb: '0px' }}>Start Date</Typography>
                                    <DatePicker
                                        value={startDate}
                                        onChange={(newValue) => setStartDate(newValue)}
                                        slots={{
                                            openPickerIcon: CalendarMonth,
                                        }}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                            },
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <Typography sx={{ mb: '0px' }}>End Date</Typography>
                                    <DatePicker
                                    value={endDate}
                                    onChange={(newValue) => setEndDate(newValue)}
                                    slots={{
                                        openPickerIcon: CalendarMonth,
                                    }}
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                        },
                                    }}
                                />
                                </Box>
                                
                            </Stack>
                        </Box>

                    </Paper>

                    {/* Publish Button */}
                    <Paper sx={{p:2}} elevation={0}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            variant="contained"
                            sx={{
                                bgcolor: '#F06B5B',
                                '&:hover': { bgcolor: '#e05a4a' },
                                width: 200,
                            }}
                        >
                            Publish Ad
                        </Button>
                    </Box>
                    </Paper>
                </Stack>
            </Box>
        </LocalizationProvider>
    );
}

