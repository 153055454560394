import { useNavigate } from "react-router-dom";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Select,
  MenuItem,
  FormControl,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CustomTable from "../../components/common/CustomTable";
import { useParams } from "react-router-dom";
import useUser from "../../hooks/useUser";
import { formatTimestamp } from "../../utils/formatHelper";
import useAd from "../../hooks/useAd";
import getLocalizedValue from "../../utils/getLocalizedValue";
import CustomPageHeader from "../../components/common/CustomPageHeader";
import useAuth from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function UserDetails() {
  const { t } = useTranslation();
  // Columns definition
  const columns = [
    { id: "_id", label: t("SN"), align: "left" },
    { id: "title", label: t("TITLE"), align: "left" },
    { id: "status", label: t("STATUS"), align: "left" },
    { id: "time", label: t("JOINED_DATE"), align: "left" },
    { id: "category", label: t("CATEGORY"), align: "left" },
    { id: "action", label: t("ACTION"), align: "right" },
  ];

  const navigate = useNavigate();
  const { id } = useParams();
  const { getUserById, user, loading } = useUser();
  const { getAdList, adLoading = loading, adList } = useAd();
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState({});
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
  });
  const [pageData, setPageData] = useState({
    totalCount: adList?.totalCount || 0,
    totalPages: adList?.totalPages || 0,
  });

  useEffect(() => {
    if (id) {
      getUserById(id)
        .then((res) => {
          setUserData(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [id]);
  useEffect(() => {
    if (id) {
      const payload = {
        page: pagination?.page,
        limit: pagination?.limit,
        filter: JSON.stringify({
          postedBy: id,
        }),
      };
      getAdList(payload);
    }
  }, [pagination.limit, pagination.page, id]);
  useEffect(() => {
    if (adList?.ads?.length > 0) {
      const items = adList?.ads;
      setData(
        items?.map((item, index) => ({
          _id:
            pagination.page * pagination.limit - pagination.limit + 1 + index,
          title: item.title,
          status: (
            <Box
              sx={
                item.status === "Active"
                  ? { color: "green" }
                  : item.status === "Inactive"
                  ? { color: "yellow" }
                  : item.status === "Sold"
                  ? { color: "red" }
                  : { color: "blue" }
              }
            >
              {item.status}
            </Box>
          ),
          time: formatTimestamp(item?.createdAt, false),
          category: getLocalizedValue(item?.category?.name),
          action: (
            <Button size="small" variant="outlined" href={`/ads/${item?._id}`}>
              {t("VIEW")}
            </Button>
          ),
        }))
      );
    } else {
      setData([]);
    }
    setPageData({
      totalCount: adList?.totalCount || 0,
      totalPages: adList?.totalPages || 0,
    });
  }, [adList]);
  const AccountSettings = ({ data }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [showPassword, setShowPassword] = useState({
      newPassword: false,
      confirmPassword: false,
    });
    const [userDetails, setUserDetails] = useState(data);
    const [changePasswordModal, setChangePasswordModal] = useState(false);
    const [password, setPassword] = useState({
      newPassword: "",
      confirmPassword: "",
    });
    const { resetPassword } = useAuth();
    const { updateUser, deleteUsers, loading } = useUser();

    const handleClose = () => {
      setChangePasswordModal(false);
    };

    const handleUpdatePassword = () => {
      if (!password?.newPassword || !password?.confirmPassword) {
        alert("Please fill all the fields");
        return;
      }
      if (password?.newPassword !== password?.confirmPassword) {
        alert("Passwords do not match");
        return;
      }
      const payload = {
        email: data?.email,
        newPassword: password?.newPassword,
      };
      resetPassword(payload)
        .then((res) => {
          if (res?.success) {
            setUserDetails(res?.data);
            setPassword({
              email: "",
              newPassword: "",
              confirmPassword: "",
            });
            setChangePasswordModal(false);
            alert("Password updated successfully");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    useEffect(() => {
      setUserDetails(data);
    }, [data]);
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        p={1}
        borderRadius={2}
        width="100%"
        margin="auto"
        gap={3}
      >
        {/* Center-aligned Heading */}
        <Typography
          variant="h6"
          textAlign="center"
          color="primary"
          gutterBottom
          fontWeight="bold"
        >
          {t("ACCOUNT_SETTINGS")}
        </Typography>

        {/* Responsive Grid Container */}
        <Grid
          container
          spacing={2}
          maxWidth="md"
          direction={isMobile ? "column" : "row"}
        >
          {/* Left Section */}
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{t("NAME")}</Typography>
            <TextField
              value={data?.name} // Replace with actual value or state variable
              fullWidth
              size="small"
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              margin="normal"
              style={{ marginTop: "0px", marginBottom: "10px" }}
            />
            <Typography variant="body2">{t("EMAIL")}</Typography>

            <TextField
              size="small"
              value={data.email} // Replace with actual value or state variable
              fullWidth
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              margin="normal"
              style={{ marginTop: "0px", marginBottom: "10px" }}
            />
            <Typography variant="body2">{t("PHONE_NUMBER")}</Typography>
            <Box display="flex" gap={1}>
              <TextField
                size="small"
                value={data?.countryCode} // Replace with actual value or state variable
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
                sx={{ width: "30%" }}
              />
              <TextField
                size="small"
                value={data?.mobile} // Replace with actual value or state variable
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
              />
            </Box>
          </Grid>

          {/* Right Section */}
          <Grid item xs={12} sm={6}>
            <Box
              display="flex"
              flexDirection="column"
              gap={2}
              paddingX={5}
              paddingY={2}
            >
              <Button
                color="black"
                fullWidth
                style={{
                  textTransform: "inherit",
                  fontWeight: "bold",
                  // backgroundColor: "background.form",
                  backgroundColor: "#80808014",
                }}
                onClick={() => setChangePasswordModal(true)}
              >
                {t("CHANGE_PASSWORD")}
              </Button>
              <Button
                variant="text"
                color="error"
                fullWidth
                style={{
                  textTransform: "inherit",
                  fontWeight: "bold",
                  backgroundColor: "#80808014",
                }}
                onClick={() => {
                  if (
                    !window.confirm(
                      "Are you sure you want to delete this account?"
                    )
                  ) {
                    return;
                  }
                  if (data?._id) {
                    deleteUsers({ ids: [data?._id] }).then((res) => {
                      if (res?.success) {
                        navigate("/users");
                      } else {
                        alert("Something went wrong");
                      }
                    });
                  }
                }}
              >
                {t("DELETE_ACCOUNT")}
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Dialog
          open={changePasswordModal}
          onClose={handleClose}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle
            textAlign="center"
            variant="h5"
            fontWeight="bold"
            color="primary"
          >
            {t("CHANGE_PASSWORD")}
          </DialogTitle>
          <DialogContent>
            <Typography variant="body2">{t("EMAIL")}</Typography>
            <TextField
              size="small"
              fullWidth
              readOnly
              value={data?.email} // Replace with actual value or state variable
              variant="outlined"
              margin="normal"
              style={{ marginTop: "0px", marginBottom: "10px" }}
              // onChange={(e) =>
              //   setPassword({ ...password, email: e.target.value })
              // }
            />{" "}
            <Typography variant="body2">{t("NEW_PASSWORD")}</Typography>
            <OutlinedInput
              size="small"
              fullWidth
              type={showPassword?.newPassword ? "text" : "password"}
              value={password?.newPassword}
              variant="outlined"
              margin="normal"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      setShowPassword({
                        ...showPassword,
                        newPassword: !showPassword?.newPassword,
                      })
                    }
                  >
                    {showPassword?.newPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              style={{ marginTop: "0px", marginBottom: "10px" }}
              onChange={(e) =>
                setPassword({ ...password, newPassword: e.target.value })
              }
            />
            <Typography variant="body2">{t("CONFIRM_PASSWORD")}</Typography>
            <OutlinedInput
              size="small"
              fullWidth
              value={password?.confirmPassword}
              type={showPassword?.confirmPassword ? "text" : "password"}
              variant="outlined"
              margin="normal"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      setShowPassword({
                        ...showPassword,
                        confirmPassword: !showPassword?.confirmPassword,
                      })
                    }
                  >
                    {showPassword.confirmPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              style={{ marginTop: "0px", marginBottom: "10px" }}
              onChange={(e) =>
                setPassword({ ...password, confirmPassword: e.target.value })
              }
            />{" "}
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                gap: "1rem",
                marginTop: "2rem",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => setChangePasswordModal(false)}
              >
                {t("CANCEL")}
              </Button>
              <Button
                variant="outlined"
                color="error"
                onClick={handleUpdatePassword}
              >
                {t("UPDATE")}
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    );
  };
  return (
    <div>
      <CustomPageHeader
        heading={t("USERS_INFO_HEADER")}
        buttontext={t("USERS_INFO_BACK")}
        path="/users"
      />

      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "20px" }}
      >
        <ProfileCard
          data={userData}
          setUserData={setUserData}
          profileLoader={loading}
        />
      </Paper>

      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "20px" }}
      >
        <AccountSettings data={userData} />
      </Paper>
      <Paper elevation={0} sx={{ borderRadius: "0px", mt: "20px" }}>
        <CustomTable
          data={data}
          columns={columns}
          loading={adLoading}
          pagination={{ ...pagination, ...pageData }}
          setPagination={setPagination}
        />
      </Paper>
    </div>
  );
}

const ProfileCard = ({ data, setUserData, profileLoader }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [userDetails, setUserDetails] = useState(data);
  const { updateUser, loading } = useUser();

  const handleStatusUpdate = (e) => {
    setUserDetails({ ...userDetails, status: e.target.value });
  };

  const handleSubmit = () => {
    if (data.status == userDetails.status) return;
    updateUser(userDetails?._id, { status: userDetails.status })
      .then((res) => {
        if (res?.success) {
          setUserData(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setUserDetails(data);
  }, [data]);
  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection={isMobile ? "column" : "row"}
      p={2}
      width="100%"
      gap={2}
    >
      {/* Left Section */}
      <Box flex={isMobile ? "1" : "0.6"} display="flex" gap={1.5}>
        <Avatar
          alt="User Name"
          src={userDetails?.profilePicture || "https://via.placeholder.com/150"} // Replace with the actual image URL
          sx={{ width: 80, height: 80 }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="h6" component="h2">
            {userDetails?.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {t("JOINED")}: {formatTimestamp(userDetails?.createdAt)}
          </Typography>
        </Box>
      </Box>

      {/* Right Section */}
      <Box
        flex={isMobile ? "1" : "0.4"}
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="space-between"
        gap={2}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            gap: "10px",
          }}
        >
          <Box sx={{ width: "50%" }}>
            {loading || profileLoader ? (
              // <Skeleton width={"100%"} height={"60px"} />
              ""
            ) : (
              <FormControl fullWidth>
                <Select
                  labelId="status-label"
                  value={userDetails?.status || data?.status}
                  onChange={handleStatusUpdate}
                  size="small"
                >
                  <MenuItem
                    value="active"
                    style={{ fontWeight: "bold", color: "green" }}
                  >
                    Active
                  </MenuItem>
                  <MenuItem
                    value="inactive"
                    style={{ fontWeight: "bold", color: "red" }}
                  >
                    Inactive
                  </MenuItem>
                </Select>
              </FormControl>
            )}
          </Box>
          <Box sx={{ width: "50%" }}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSubmit}
            >
              {t("UPDATE")}
            </Button>
          </Box>
        </Box>

        <Button variant="contained" color="primary" fullWidth>
          {t("SEND_MESSAGE")}
        </Button>
      </Box>
    </Box>
  );
};

export default UserDetails;
