import api from "../utils/API";

export const userService = {
  fetchUsers: async (payload) => {
    const response = await api.get("/admin/users", {
      params: payload,
    });
    return response.data;
  },
  fetchUserById: async (id) => {
    const response = await api.get(`/admin/users/${id}`);
    return response.data;
  },
  updateUser: async (id, payload) => {
    const response = await api.put(`/admin/users/${id}`, payload);
    return response.data;
  },
  deleteUsers: async (payload) => {
    const response = await api.delete("/admin/users/delete", {
      data: payload,
    });
    return response.data;
  },
};
