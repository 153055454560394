
import { settingsService } from "../services/settings.api";

const useSettings = () => {

  const getSettingsData = async (payload) => {
    const data = await settingsService.fetchSettigns(payload);
    return data;
  };
  
  const updateSettingsData = async (payload) => {
      const data = await settingsService.updateSettigns(payload);
      return data;
    };
    
    
    const getFaqData = async () => {
      const data = await settingsService.fetchFaqlist();
      return data;
    };
    
    const getFaq = async (id) => {
      const data = await settingsService.getFaq(id);
      return data;
    };
    
    const createFaq = async (payload) =>{
        const data = await settingsService.createFaq(payload);
        return data;
    }

    const updateFaq = async (id,payload) =>{
        const data = await settingsService.updateFaq(id,payload);
        return data;
    }
    
    const deleteFaq = async (faqId) =>{
        const data = await settingsService.deleteFaq(faqId);
        return data;
    }
    
    const getAdSettings = async ()=>{
        const data = await settingsService.getAdSettings();
        return data; 
    }
    const updateAdSettings = async (payload)=>{
        const data = await settingsService.updateAdSettings(payload);
        return data; 
    }

    const getAllCountry = async ()=>{
        const data = await settingsService.getAllCountry();
        return data; 
    }

    const createCountry = async (payload)=>{
        const data = await settingsService.createCountry(payload);
        return data; 
    }

    const deleteCountry = async (payload)=>{
        const data = await settingsService.deleteCountry(payload);
        return data;
    }

    const uploadGeneralSettings = async (values,footerText,logos,socialLinks) => {
      console.log(values,footerText,logos,socialLinks)
      const formData = new FormData();
    
      // Append the text field values
      formData.append('email', values.email);
      formData.append('phone', values.phone);
      formData.append('phoneCode', values.phoneCode);
      formData.append('whatsAppNumber', values.whatsAppNumber);
      formData.append('whatsAppCode', values.whatsAppCode);
      formData.append('enableEmailInContactUs', values.enableEmailInContactUs);
      formData.append('enablePhoneInContactUs', values.enablePhoneInContactUs);
      formData.append('enableWhatsAppInContactUs', values.enableWhatsAppInContactUs);
      formData.append('enableChatInContactUs', values.enableChatInContactUs);
    
      // Append footer text (if needed)
      formData.append('footerText_en', footerText.en);
      formData.append('footerText_ar', footerText.ar);
    
      // Append logos (if files exist)
      if (typeof logos.footerLogo !=='string') {
        formData.append('footerLogo', logos.footerLogo[0]);
      }
      if (typeof logos.headerLogo !=='string') {
        formData.append('headerLogo', logos.headerLogo[0]);
      }
      if (typeof logos.profileLogo !== 'string') {
        formData.append('profileLogo', logos.profileLogo[0]);
      }

      // Append social media logos (if files exist)
      socialLinks.forEach((account) => {
        if (account.icon && typeof account.icon !== "string") {
          formData.append("socialMediaLogos", account.icon[0]);
          account.icon = account.icon[0].name;
        }
      });

      formData.append("socialMediaAccounts", JSON.stringify(socialLinks));           
      console.log('formData',{...formData})
    
      return updateSettingsData(formData)
    };
    


  return {
    getSettingsData,
    updateSettingsData,
    getFaqData,
    createFaq,
    updateFaq,
    deleteFaq,
    getFaq,
    getAdSettings,
    updateAdSettings,
    getAllCountry,
    createCountry,
    deleteCountry,
    uploadGeneralSettings
  };
};

export default useSettings;
