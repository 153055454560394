import * as React from "react";
import i18n from "i18next";
import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
  CircularProgress,
} from "@mui/material";
import CustomTable from "../../components/common/CustomTable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate, useParams } from "react-router-dom";
import useCategory from "../../hooks/useCategory";
import { useEffect, useState } from "react";
import getLocalizedValue from "../../utils/getLocalizedValue";
import { useFormik } from "formik";
import CustomeMultiSelectDialog from "../../components/common/CustomeMultiSelectDialog";
import useSpecification from "../../hooks/useSpecifications";
import useAdditionalDetails from "../../hooks/useAdditionalDetails";
import { ArrowBack } from "@mui/icons-material";
import CustomPageHeader from "../../components/common/CustomPageHeader";
import { useTranslation } from "react-i18next";

function MenuEditPage() {
  const { t } = useTranslation();
  // Columns definition
  const columns = [
    { id: "id", label: "", align: i18n.language == 'en' ? 'left': 'right' },
    { id: "icon", label: t("ICON"), align: i18n.language == 'en' ? 'left': 'right' },
    { id: "name", label: t("ITEMS_NAME"), align: i18n.language == 'en' ? 'left': 'right' },
    { id: "displayOrder", label: t("DISPLAY_ORDER"), align: "center" },
    { id: "action", label: t("ACTION"), align: "center" },
  ];

  const { id } = useParams();
  const {
    categories,
    fetchCategories,
    getCategoryById,
    category,
    loading,
    updateCategory,
  } = useCategory();
  const { specifications, getSpecificationsList } = useSpecification();
  const { getAdditionalDetails, additionalDetails } = useAdditionalDetails();
  const [specificationItems, setSpecificationItems] = useState([]);
  const [optionalSpecificationItems, setOptionalSpecificationItems] = useState(
    []
  );
  const [additionalItem, setAdditionalItems] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleClickOpen = async (label) => {
    setSelectedLabel(label);
    if (label === "SPECIFICATIONS") {
      await getSpecificationsList();
      setSelectedOptions(formik.values.specification.items);
      setOptions(specifications?.specifications);
    } else if (label === "OPTIONAL_SPECIFICATIONS") {
      await getSpecificationsList();
      setSelectedOptions(formik.values.optionalSpecifications.items);
      setOptions(specifications?.specifications);
    } else if (label === "ADDITIONAL_DETAILS") {
      await getAdditionalDetails();
      setSelectedOptions(formik.values.additionalDetails.items);
      setOptions(additionalDetails.additionalDetails);
    }
    setOpen(true);
  };

  const handleAddItems = (values, label) => {
    const mapItems = (items) =>
      items?.map((item, index) => ({
        id: (
          // <Box sx={{ maxWidth: "10px" }}>
            <Checkbox />
          // </Box>
        ),
        _id: item._id,
        icon: (
          item.iconImage
            ? <img
              src={item.iconImage}
              alt="icon"
              style={{ width: "40px", height: "40px" }}
            />
            : ""
        ),
        name: getLocalizedValue(item.name),
        displayOrder: index + 1,
        action: (
          <Button
            size="small"
            variant="outlined"
            color="black"
            sx={{
              textTransform: "none",
              padding: "2px 5px",
              minWidth: "45px",
              fontWeight: "bold"
            }}
            onClick={() => handleRemoveItem(item, label, items)} // Bind remove function here
          >
            {t('REMOVE')}
          </Button>
        ),
      }));

    const setFieldValue = (field, value) => {
      formik.setFieldValue(field, value);
    };

    // Handle each category
    switch (label) {
      case "Specifications":
        setSpecificationItems(mapItems(values));
        setFieldValue("specification.items", values);
        break;

      case "Optional Specifications":
        setOptionalSpecificationItems(mapItems(values));
        setFieldValue("optionalSpecifications.items", values);
        break;

      case "Additional Details":
        setAdditionalItems(mapItems(values));
        setFieldValue("additionalDetails.items", values);
        break;

      default:
        break;
    }

    setOpen(false);
  };

  const handleRemoveItem = (item, label, items) => {
    let updatedItems;
    const mapItems = (items) =>
      items?.map((item, index) => ({
        id: (
          // <Box sx={{ maxWidth: "10px" }}>
            <Checkbox />
          // </Box>
        ),
        _id: item._id,
        icon: (
          item.iconImage
            ? <img
              src={item.iconImage}
              alt="icon"
              style={{ width: "40px", height: "40px" }}
            />
            : ""
        ),
        name: getLocalizedValue(item.name),
        displayOrder: index + 1,
        action: (
          <Button
            size="small"
            variant="outlined"
            color="black"
            sx={{
              textTransform: "none",
              padding: "2px 5px",
              minWidth: "45px",
              fontWeight: "bold"
            }}
            onClick={() => handleRemoveItem(item, label, items)}
          >
            {t('REMOVE')}
          </Button>
        ),
      }));
    if (label === "Specifications") {
      updatedItems = items.filter((specItem) => specItem._id !== item._id);
      formik.setFieldValue("specification.items", updatedItems);
      setSpecificationItems(mapItems(updatedItems));
    } else if (label === "Optional Specifications") {
      updatedItems = items.filter((specItem) => specItem._id !== item._id);
      setOptionalSpecificationItems(mapItems(updatedItems));
      formik.setFieldValue("optionalSpecifications.items", updatedItems);
    } else if (label === "Additional Details") {
      updatedItems = items.filter((specItem) => specItem._id !== item._id);
      setAdditionalItems(mapItems(updatedItems));
      formik.setFieldValue("additionalDetails.items", updatedItems);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: {
        en: "",
        ar: "",
      },
      additionalDetails: { isEnabled: true, items: [] },
      optionalSpecifications: { isEnabled: true, items: [] },
      specification: { isEnabled: true, items: [] },
    },
    onSubmit: async (values) => {
      try {
        const transformItems = (items) =>
          items.map((item, index) => ({
            id: item._id,
            displayOrder: index + 1,
          }));

        const payload = {
          additionalDetails: {
            ...values.additionalDetails,
            items: transformItems(values.additionalDetails.items),
          },
          optionalSpecifications: {
            ...values.optionalSpecifications,
            items: transformItems(values.optionalSpecifications.items),
          },
          specification: {
            ...values.specification,
            items: transformItems(values.specification.items),
          },
        };

        if (id) {
          await updateCategory(id, payload);
        }
        navigate("/menu");
      } catch (error) {
        console.error("Error during form submission:", error);
        alert("Oops.. Something went wrong.");
      }
    },
  });

  useEffect(() => {
    fetchCategories({ limit: 100 });
  }, []);

  useEffect(() => {
    if (id) {
      getCategoryById(id);
    }
  }, [id]);

  useEffect(() => {
    if (category) {
      // Helper function to map items with Remove button
      const mapItems = (items, label) =>
        items?.map((item) => ({
          id: (
            // <Box sx={{ maxWidth: "10px" }}>
              <Checkbox />
            // </Box>
          ),
          _id: item._id,
          icon: (
            item.iconImage
              ? <img
                src={item.iconImage}
                alt="icon"
                style={{ width: "40px", height: "40px" }}
              />
              : ""
          ),
          name: getLocalizedValue(item.name),
          displayOrder: item.displayOrder,
          action: (
            <Button
              size="small"
              variant="outlined"
              color="black"
              sx={{
                textTransform: "none",
                padding: "2px 5px",
                minWidth: "45px",
                fontWeight: "bold"
              }}
              onClick={() => handleRemoveItem(item, label, items)}
            >
              {t('REMOVE')}
            </Button>
          ),
        }));

      // Setting form values
      formik.setValues({
        additionalDetails: {
          isEnabled: category.additionalDetails?.isEnabled || false,
          items: category.additionalDetails?.items || [],
        },
        optionalSpecifications: {
          isEnabled: category.optionalSpecifications?.isEnabled || false,
          items: category.optionalSpecifications?.items || [],
        },
        specification: {
          isEnabled: category.specification?.isEnabled || false,
          items: category.specification?.items || [],
        },
      });

      // Setting items for the different categories with "Remove" button
      setSpecificationItems(
        mapItems(category?.specification?.items || [], "Specifications")
      );
      setOptionalSpecificationItems(
        mapItems(
          category?.optionalSpecifications?.items || [],
          "Optional Specifications"
        )
      );
      setAdditionalItems(
        mapItems(category?.additionalDetails?.items || [], "Additional Details")
      );
    }
  }, [category]);

  return (
    <Box sx={{ width: "100%" }}>
      <CustomPageHeader
        heading={t('MENU_ITEM_HEADER')}
        buttontext={t('BACK_TO_LIST')}
        path="/menu"
      />
      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", padding: "20px", mt: "20px" }}
      >
        {loading ? (
          <div
            style={{
              minHeight: "250px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size={24} />
          </div>
        ) : (
          <Container maxWidth="sm">
            <Typography
              variant="body1"
              fontWeight="bold"
              color="primary"
              textAlign="center"
            >
              {t('PARENT_CATEGORY_SELECTION')}
            </Typography>
            <Box style={{ width: "100%", margin: "20px 0px" }}>
              <Typography id="demo-simple-select-label" fontWeight="bold">
                {t('SELECT_MENU_ITEM')}
              </Typography>
              <Select
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={id}
                size="small"
                name="primary_category"
                onChange={(e) => navigate(`/menu/${e.target.value}`)}
              >
                {categories?.categories?.map((item, index) => (
                  <MenuItem key={index} value={item._id}>
                    {item.name.en}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Box
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                margin: "20px 0px",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                sx={{ mx: "5px", textTransform: "none", minWidth: "150px", fontWeight:"bold" }}
                size="large"
                disabled={!id}
                onClick={() => navigate(`/menu/sub/${id}`)}
              >
                {t('EDIT_SUB_CATEGORY_ITEMS')}
              </Button>
            </Box>
          </Container>
        )}
      </Paper>

      <Paper elevation={0} sx={{ borderRadius: "0px", mt: "20px" }}>
        <Accordion defaultExpanded elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="primary" fontSize="large" />}
          >
            <Box style={{ textAlign: "center", width: "100%" }}>
              <Typography
                textAlign="center"
                variant="h4"
                fontWeight="bold"
                color="primary"
              >
                {t('SPECIFICATION_ITEMS')}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Container maxWidth="sm">
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <FormControl component="fieldset">
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...formik.getFieldProps("specification.isEnabled")}
                          checked={formik.values.specification.isEnabled}
                        />
                      }
                      label={t('ENABLE')}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!formik.values.specification.isEnabled}
                          onChange={(e) =>
                            formik.setFieldValue(
                              "specification.isEnabled",
                              !e.target.checked
                            )
                          }
                        />
                      }
                      label={t('DISABLE')}
                    />
                  </FormGroup>
                </FormControl>
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "20px 0px",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    mx: "5px",
                    textTransform: "none",
                    minWidth: "150px",
                    fontWeight: "bold"
                  }}
                  size="large"
                  onClick={() => handleClickOpen('SPECIFICATIONS')}
                >
                  {t('ADD_REQUIRED_SPECIFICATION_ITEM')}
                </Button>
              </Box>
            </Container>
            <CustomTable
              data={specificationItems}
              columns={columns}
              hidePagination
            />
          </AccordionDetails>
        </Accordion>
      </Paper>

      <Paper elevation={0} sx={{ borderRadius: "0px", mt: "20px" }}>
        <Accordion defaultExpanded elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="primary" fontSize="large" />}
          >
            <Box style={{ textAlign: "center", width: "100%" }}>
              <Typography
                textAlign="center"
                variant="h4"
                fontWeight="bold"
                color="primary"
              >
                {t('OPTIONAL_SPECIFICATION_ITEMS')}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Container maxWidth="sm">
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <FormControl component="fieldset">
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...formik.getFieldProps(
                            "optionalSpecifications.isEnabled"
                          )}
                          checked={
                            formik.values.optionalSpecifications.isEnabled
                          }
                        />
                      }
                      label={t('ENABLE')}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            !formik.values.optionalSpecifications.isEnabled
                          }
                          onChange={(e) =>
                            formik.setFieldValue(
                              "optionalSpecifications.isEnabled",
                              !e.target.checked
                            )
                          }
                        />
                      }
                      label={t('DISABLE')}
                    />
                  </FormGroup>
                </FormControl>
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "20px 0px",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    mx: "5px",
                    textTransform: "none",
                    minWidth: "150px",
                    fontWeight: "bold"
                  }}
                  size="large"
                  onClick={() => handleClickOpen("OPTIONAL_SPECIFICATIONS")}
                >
                  {t('ADD_OPTIONAL_SPECIFICATION_ITEM')}
                </Button>
              </Box>
            </Container>
            <CustomTable
              data={optionalSpecificationItems}
              columns={columns}
              hidePagination
            />
          </AccordionDetails>
        </Accordion>
      </Paper>

      <Paper elevation={0} sx={{ borderRadius: "0px", mt: "20px" }}>
        <Accordion defaultExpanded elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon color="primary" fontSize="large" />}
          >
            <Box style={{ textAlign: "center", width: "100%" }}>
              <Typography
                textAlign="center"
                variant="h4"
                fontWeight="bold"
                color="primary"
              >
                {t('ADDITIONAL_DETAILS')}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Container maxWidth="sm">
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <FormControl component="fieldset">
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...formik.getFieldProps(
                            "additionalDetails.isEnabled"
                          )}
                          checked={formik.values.additionalDetails.isEnabled}
                        />
                      }
                      label={t('ENABLE')}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!formik.values.additionalDetails.isEnabled}
                          onChange={(e) =>
                            formik.setFieldValue(
                              "additionalDetails.isEnabled",
                              !e.target.checked
                            )
                          }
                        />
                      }
                      label={t('DISABLE')}
                    />
                  </FormGroup>
                </FormControl>
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "20px 0px",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    mx: "5px",
                    textTransform: "none",
                    minWidth: "150px",
                    fontWeight: "bold"
                  }}
                  size="large"
                  onClick={() => handleClickOpen("ADDITIONAL_DETAILS")}
                >
                  {t('ADD_ADDITIONAL_DETAILS_ITEM')}
                </Button>
              </Box>
            </Container>
            <CustomTable
              data={additionalItem}
              columns={columns}
              hidePagination
            />
          </AccordionDetails>
        </Accordion>
      </Paper>

      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "20px" }}
      >
        <Box display="flex" justifyContent="center">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ textTransform: "none", minWidth: "150px", fontWeight: "bold" }}
            onClick={formik.handleSubmit}
            disabled={!id}
          >
            {t('SAVE')}
          </Button>
        </Box>
      </Paper>

      {!loading && (
        <CustomeMultiSelectDialog
          open={open}
          setOpen={setOpen}
          options={options}
          selectedOptions={selectedOptions}
          handleAddItems={handleAddItems}
          title={t(selectedLabel)}
          loading={loading}
        />
      )}
    </Box>
  );
}

export default MenuEditPage;
