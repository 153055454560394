import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Paper,
  Container,
} from "@mui/material";
import CustomPageHeader from "../../components/common/CustomPageHeader";
import CustomTabPanel from "../../components/common/CustomTabPanel";
import TextEditor from "../../components/common/TextEditor";
import { useTranslation } from "react-i18next";
import useSettings from "../../hooks/useSettings";
import { useFormik } from "formik";
import * as Yup from "yup";
import useConfig from "../../hooks/useConfig";

const generateRandom8DigitId = () => {
  return Math.floor(10000000 + Math.random() * 90000000).toString();
};

export default function GeneralSettings() {
  const { t } = useTranslation();
  const { getSettingsData, uploadGeneralSettings } = useSettings();
  const { fetchCountry } = useConfig();

  const [footerText, setFooterText] = useState({
    en: "<p>Welcome!</p>",
    ar: "<p>Welcome!</p>",
  });
  const [value, setValue] = useState(0);
  const [countriesList, setCountriesList] = useState([]);
  const [socialLinks, setSocialLinks] = useState([
    { oldIcon: "", icon: "", url: "", id: generateRandom8DigitId() },
  ]);

  const [logos, setLoges] = useState({
    footerLogo: "",
    headerLogo: "",
    profileLogo: "",
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      phoneCode: "+965",
      phone: "",
      whatsAppCode: "+965",
      whatsAppNumber: "",
      enableEmailInContactUs: true,
      enablePhoneInContactUs: true,
      enableWhatsAppInContactUs: true,
      enableChatInContactUs: true,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t("INVALID_EMAIL"))
        .required(t("EMAIL_REQUIRED")),
      phone: Yup.string()
        .matches(/^\d+$/, t("PHONE_NUMBER_ONLY_DIGITS"))
        .min(8, t("PHONE_MIN_LENGTH"))
        .required(t("PHONE_REQUIRED")),
      whatsAppNumber: Yup.string()
        .matches(/^\d+$/, t("WHATSAPP_ONLY_DIGITS"))
        .min(8, t("WHATSAPP_MIN_LENGTH"))
        .required(t("WHATSAPP_REQUIRED")),
    }),
    onSubmit: async (values) => {
      console.log(values);
      const response = await uploadGeneralSettings(
        values,
        footerText,
        logos,
        socialLinks
      );
      if (response.success) {
        console.log(response.data.socialMediaAccounts, "<<<< response.data");
        response.data.socialMediaAccounts?.map((account) => {
          account.oldIcon = account.icon;
          account.id = generateRandom8DigitId();
        });
        setSocialLinks(response.data.socialMediaAccounts);
        alert(response.message);
      } else {
        alert(response.errorMessage);
      }
    },
  });

  const handleEditorChange = (content, key) => {
    setFooterText((value) => ({ ...value, [key]: content }));
  };

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const handleAddLink = () => {
    setSocialLinks((pre) => [
      ...pre,
      { oldIcon: "", icon: "", url: "", id: generateRandom8DigitId() },
    ]);
  };

  const handleIconChange = (event, id) => {
    const file = event.target.files;
    if (file) {
      setSocialLinks((prev) =>
        prev.map((link) => {
          if (link?.id === id) {
            link.icon = file;
          }
          return link;
        })
      );
    }
  };

  const handleRemoveLink = (id) => {
    setSocialLinks((prev) => prev.filter((link) => link.id !== id));
  };

  const handleFileChange = (event, key) => {
    const file = event.target.files;
    if (file) {
      setLoges((pre) => ({ ...pre, [key]: file }));
    }
  };

  const fetchAll = async () => {
    let data = await getSettingsData();
    if (data.success) {
      setFooterText(data?.data?.footerText);
      const {
        email,
        enableEmailInContactUs,
        phone,
        phoneCode,
        enablePhoneInContactUs,
        whatsAppNumber,
        whatsAppCode,
        enableWhatsAppInContactUs,
        enableChatInContactUs,
        footerLogo,
        headerLogo,
        profileLogo,
        socialMediaAccounts,
      } = data.data;
      formik.setValues({
        email: email || "",
        phoneCode: phoneCode || "+965",
        phone: phone || "",
        whatsAppCode: whatsAppCode || "+965",
        whatsAppNumber: whatsAppNumber || "",
        enableEmailInContactUs: enableEmailInContactUs || false,
        enablePhoneInContactUs: enablePhoneInContactUs || false,
        enableWhatsAppInContactUs: enableWhatsAppInContactUs || false,
        enableChatInContactUs: enableChatInContactUs || false,
      });
      setLoges({ footerLogo, headerLogo, profileLogo });
      socialMediaAccounts?.map((account) => {
        account.oldIcon = account.icon;
        account.id = generateRandom8DigitId();
      });
      setSocialLinks(socialMediaAccounts);
    }

    let countryData = await fetchCountry();
    console.log(countryData, "hello world");

    if (countryData.success) {
      setCountriesList(countryData?.data?.countries);
    }
  };

  useEffect(() => {
    fetchAll();
  }, []);

  return (
    <Box sx={{ maxWidth: 1200, mx: "auto", p: 1 }}>
      {/* Header */}
      <CustomPageHeader
        heading={t("GENERAL_SETTINGS")}
        buttontext={t("BACK_TO_SETTINGS")}
        path="/settings"
      />

      <Stack spacing={2}>
        {/* Logo Upload Section */}
        <Grid container>
          <Grid item xs={12} md={4} mt={2} pr={2}>
            <Paper elevation={0}>
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  height: 350,
                }}
              >
                <Typography
                  variant="subtitle1"
                  fontWeight="bold"
                  color="#F06B5B"
                  gutterBottom
                  mb={3}
                >
                  {t("HEADER_LOGO")}
                </Typography>
                <Typography mb={1} variant="subtitle1" fontWeight="bold">
                  {t("IMAGE")}
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    height: 130,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mb: 6,
                  }}
                >
                  <img
                    src={
                      typeof logos.headerLogo === "string"
                        ? logos.headerLogo
                        : URL.createObjectURL(logos.headerLogo[0])
                    }
                    alt="Header Logo"
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                  />
                </Box>

                <Button
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  sx={{
                    textTransform: "none",
                    minWidth: "170px",
                    fontWeight: "bold",
                  }}
                >
                  {t("UPLOAD_IMAGE")}
                  <input
                    type="file"
                    onChange={(e) => handleFileChange(e, "headerLogo")}
                    id="imageUploadInput"
                    accept="image/*"
                    style={{ display: "none" }}
                    multiple={false}
                  />
                </Button>
              </CardContent>
            </Paper>
          </Grid>

          <Grid item xs={12} md={4} mt={2}>
            <Paper elevation={0}>
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  height: 350,
                }}
              >
                <Typography
                  variant="subtitle1"
                  fontWeight="bold"
                  color="#F06B5B"
                  gutterBottom
                  mb={3}
                >
                  {t("FOOTER_LOGO")}
                </Typography>
                <Typography mb={1} variant="subtitle1" fontWeight="bold">
                  {t("IMAGE")}
                </Typography>
                <Box
                  sx={{
                    width: "45%",
                    height: 140,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "primary.main",
                    mb: 6,
                  }}
                >
                  <img
                    src={
                      typeof logos.footerLogo === "string"
                        ? logos.footerLogo
                        : URL.createObjectURL(logos.footerLogo[0])
                    }
                    alt="Footer Logo"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "https://via.placeholder.com/150";
                    }}
                    style={{ maxWidth: "80%", maxHeight: "100%" }}
                  />
                </Box>
                <Button
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  sx={{
                    textTransform: "none",
                    minWidth: "170px",
                    fontWeight: "bold",
                  }}
                >
                  {t("UPLOAD_IMAGE")}
                  <input
                    type="file"
                    onChange={(e) => handleFileChange(e, "footerLogo")}
                    id="imageUploadInput"
                    accept="image/*"
                    style={{ display: "none" }}
                    multiple={false}
                  />
                </Button>
              </CardContent>
            </Paper>
          </Grid>

          <Grid item xs={12} md={4} mt={2} pl={2}>
            <Paper elevation={0}>
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  height: 350,
                }}
              >
                <Typography
                  variant="subtitle1"
                  fontWeight="bold"
                  color="#F06B5B"
                  gutterBottom
                  mb={3}
                >
                  {t("PROFILE_LOGO")}
                </Typography>
                <Typography mb={1} variant="subtitle1" fontWeight="bold">
                  {t("IMAGE")}
                </Typography>
                <Box
                  sx={{
                    width: "45%",
                    height: 140,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mb: 6,
                  }}
                >
                  <img
                    src={
                      typeof logos.profileLogo === "string"
                        ? logos.profileLogo
                        : URL.createObjectURL(logos.profileLogo[0])
                    }
                    alt="Profile Logo"
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "https://via.placeholder.com/150";
                    }}
                  />
                </Box>
                <Button
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  sx={{
                    textTransform: "none",
                    minWidth: "170px",
                    fontWeight: "bold",
                  }}
                >
                  {t("UPLOAD_IMAGE")}
                  <input
                    type="file"
                    onChange={(e) => handleFileChange(e, "profileLogo")}
                    id="imageUploadInput"
                    accept="image/*"
                    style={{ display: "none" }}
                    multiple={false}
                  />
                </Button>
              </CardContent>
            </Paper>
          </Grid>
        </Grid>

        {/* Social Media Links */}
        <Paper elevation={0}>
          <CardContent>
            <Typography
              variant="h6"
              color="#F06B5B"
              gutterBottom
              textAlign="center"
              fontWeight="bold"
            >
              {t("ADD_SOCIAL_MEDIA_LINKS")}
            </Typography>
            <Stack spacing={2}>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="subtitle1" fontWeight="bold" ml={2}>
                  {t("IMAGE")}
                </Typography>
                <Typography variant="subtitle1" fontWeight="bold" mr={6}>
                  {t("ENTER_URL")}
                </Typography>
                <Box />
              </Box>
              {socialLinks.map((social, index) => (
                <Box
                  key={social.platform}
                  sx={{ display: "flex", gap: 2, alignItems: "center" }}
                >
                  <Box
                    sx={{
                      height: "35px",
                      width: "100px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#eb6953",
                      borderRadius: "5px",
                    }}
                  >
                    <img
                      src={
                        typeof social.icon === "string"
                          ? social.icon
                          : URL.createObjectURL(social.icon[0])
                      }
                      alt="sold"
                      style={{
                        height: "30px",
                        width: "auto",
                        objectFit: "contain",
                      }}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "https://via.placeholder.com/150";
                      }}
                    />
                  </Box>
                  <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    sx={{
                      whiteSpace: "nowrap",
                      textTransform: "none",
                      fontWeight: "bold",
                    }}
                  >
                    {t("ADD_ICON")}
                    <input
                      type="file"
                      onChange={(e) => handleIconChange(e, social.id)}
                      id="imageUploadInput"
                      accept="image/*"
                      style={{ display: "none" }}
                      multiple={false}
                    />
                  </Button>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder={`www.platform.com/soldkw`}
                    value={social.url}
                    type="url"
                    onChange={(e) => {
                      const newLinks = [...socialLinks];
                      newLinks[index].url = e.target.value;
                      setSocialLinks(newLinks);
                    }}
                  />
                  <Button
                    variant="contained"
                    onClick={() => handleRemoveLink(social.id)}
                    sx={{
                      textTransform: "none",
                      width: "150px",
                      bgcolor: "grey.600",
                      fontWeight: "bold",
                      "&:hover": { bgcolor: "grey.700" },
                      bgcolor: "grey.600",
                      "&:hover": { bgcolor: "grey.700" },
                    }}
                  >
                    {t("DELETE")}
                  </Button>
                </Box>
              ))}
            </Stack>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                sx={{
                  mt: 2,
                  textTransform: "none",
                  minWidth: "170px",
                  fontWeight: "bold",
                }}
                onClick={handleAddLink}
              >
                {t("ADD_A_LINK")}
              </Button>
            </Box>
          </CardContent>
        </Paper>

        {/* Footer Text */}
        <Paper elevation={0} style={{ padding: "30px", marginTop: "20px" }}>
          <Container maxWidth="sm">
            <Typography
              style={{
                textAlign: "center",
                fontWeight: "bold",
                marginBottom: "30px",
              }}
              color="primary"
            >
              {t("ENTER_FOOTER_TEXT")}
            </Typography>
          </Container>
          <Container maxWidth="sm">
            <Box style={{ display: "flex", width: "100%" }}>
              <Button
                variant={value === 0 ? "contained" : "text"}
                style={{
                  borderRadius: "0px",
                  width: "50%",
                  fontSize: "20px",
                  fontWeight: "bold",
                  textTransform: "none",
                }}
                onClick={() => handleChange(0)}
              >
                {t("ENGLISH")}
              </Button>
              <Button
                variant={value === 1 ? "contained" : "text"}
                style={{
                  borderRadius: "0px",
                  width: "50%",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
                onClick={() => handleChange(1)}
              >
                {t("ARABIC")}
              </Button>
            </Box>
          </Container>
          <Container maxWidth="md">
            <CustomTabPanel value={value} index={0}>
              <Typography variant="body1" fontWeight="bold">
                {t("ENGLISH")}
              </Typography>
              <TextEditor
                value={footerText.en}
                onChange={(value) => handleEditorChange(value, "en")}
                language={"en"}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Typography
                variant="body1"
                style={{ textAlign: "end" }}
                fontWeight="bold"
              >
                {t("ARABIC")}
              </Typography>
              <TextEditor
                value={footerText.ar}
                onChange={(value) => handleEditorChange(value, "ar")}
                language={"ar"}
              />
            </CustomTabPanel>
          </Container>
        </Paper>

        {/* Contact Us */}
        <Paper elevation={0}>
          <CardContent>
            <Typography
              variant="h6"
              color="#F06B5B"
              fontWeight="bold"
              textAlign="center"
              gutterBottom
            >
              {t("CONTACT_US")}
            </Typography>
            <Stack spacing={1} style={{ marginTop: "20px" }}>
              {/* Email Section */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <Typography
                  style={{ width: "33%", textAlign: "end", fontWeight: "bold" }}
                >
                  {t("ENTER_EMAIL")}
                </Typography>
                <Box style={{ width: "33%" }}>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="support@soldkw.com"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    InputProps={{
                      sx: { fontWeight: "bold" },
                    }}
                  />
                </Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.enableEmailInContactUs}
                      onChange={formik.handleChange}
                      name="enableEmailInContactUs"
                    />
                  }
                  label={t("ENABLE_EMAIL_IN_CONTACT_US")}
                  sx={{
                    width: "33%",
                    "& .MuiFormControlLabel-label": {
                      fontWeight: "bold",
                    },
                  }}
                />
              </Box>

              {/* Phone Section */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                <Typography
                  style={{ width: "33%", textAlign: "end", fontWeight: "bold" }}
                >
                  {t("ENTER_PHONE_NUMBER")}
                </Typography>
                <Box sx={{ display: "flex", gap: 2, width: "33%" }}>
                  <Select
                    name="phoneCode"
                    value={formik.values.phoneCode}
                    onChange={formik.handleChange}
                    size="small"
                    sx={{
                      width: 120,
                      "& .MuiSelect-select": { fontWeight: "bold" },
                    }}
                  >
                    {countriesList.map((item) => {
                      return (
                        <MenuItem value={item.phoneCode}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: ".5rem",
                            }}
                          >
                            <img
                              src={item?.image}
                              alt={item.phoneCode}
                              style={{ width: "20px", height: "15px" }}
                            />
                            <span>{item.phoneCode}</span>
                          </Box>
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="12345678"
                    name="phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    helperText={formik.touched.phone && formik.errors.phone}
                    InputProps={{
                      sx: { fontWeight: "bold" },
                    }}
                  />
                </Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.enablePhoneInContactUs}
                      onChange={formik.handleChange}
                      name="enablePhoneInContactUs"
                    />
                  }
                  label={t("ENABLE_PHONE_IN_CONTACT_US")}
                  sx={{
                    width: "33%",
                    "& .MuiFormControlLabel-label": {
                      fontWeight: "bold",
                    },
                  }}
                />
              </Box>

              {/* WhatsApp Section */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <Typography
                  style={{ width: "33%", textAlign: "end", fontWeight: "bold" }}
                >
                  {t("ENTER_WHATSAPP_NUMBER")}
                </Typography>
                <Box sx={{ display: "flex", gap: 2, width: "33%" }}>
                  <Select
                    name="whatsAppCode"
                    value={formik.values.whatsAppCode}
                    onChange={formik.handleChange}
                    sx={{
                      width: 120,
                      "& .MuiSelect-select": { fontWeight: "bold" },
                    }}
                    size="small"
                  >
                    {countriesList.map((item) => {
                      return (
                        <MenuItem value={item.phoneCode}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: ".5rem",
                            }}
                          >
                            <img
                              src={item?.image}
                              alt={item.phoneCode}
                              style={{ width: "20px", height: "15px" }}
                            />
                            <span>{item.phoneCode}</span>
                          </Box>
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="12345678"
                    name="whatsAppNumber"
                    value={formik.values.whatsAppNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.whatsAppNumber &&
                      Boolean(formik.errors.whatsAppNumber)
                    }
                    helperText={
                      formik.touched.whatsAppNumber &&
                      formik.errors.whatsAppNumber
                    }
                    InputProps={{
                      sx: { fontWeight: "bold" },
                    }}
                  />
                </Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.enableWhatsAppInContactUs}
                      onChange={formik.handleChange}
                      name="enableWhatsAppInContactUs"
                    />
                  }
                  label={t("ENABLE_WHATSAPP_IN_CONTACT_US")}
                  sx={{
                    width: "33%",
                    "& .MuiFormControlLabel-label": {
                      fontWeight: "bold",
                    },
                  }}
                />
              </Box>

              {/* Chat Section */}
              <Stack
                spacing={1}
                style={{
                  alignItems: "center",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.enableChatInContactUs}
                      onChange={formik.handleChange}
                      name="enableChatInContactUs"
                    />
                  }
                  label={t("ENABLE_CHAT_IN_CONTACT_US")}
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontWeight: "bold",
                    },
                  }}
                />
              </Stack>
            </Stack>
          </CardContent>
        </Paper>

        {/* Action Buttons */}
        <Paper
          elevation={0}
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: 2,
            padding: "20px",
          }}
        >
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              fontWeight: "bold",
              bgcolor: "grey.600",
              "&:hover": { bgcolor: "grey.700" },
              minWidth: "150px",
            }}
            onClick={() => window.history.back()}
          >
            {t("BACK")}
          </Button>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              bgcolor: "#F06B5B",
              "&:hover": { bgcolor: "#e05a4a" },
              minWidth: "150px",
              fontWeight: "bold",
            }}
            onClick={formik.handleSubmit}
          >
            {t("SAVE")}
          </Button>
        </Paper>
      </Stack>
    </Box>
  );
}
