import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

function TextEditor(props) {
  const {language = 'en',onChange,value} = props
  return (
    <Editor
      apiKey="sgzwd4s084lnbmcgzvux2as0xg837qu36088cb2rak6ztkp8"
      init={{
        plugins: [
          'anchor', 'autolink', 'charmap', 'codesample', 'emoticons', 'image', 'link', 'lists', 'media', 
          'searchreplace', 'table', 'visualblocks', 'wordcount', 'directionality', // Add directionality plugin
        ],
        toolbar:'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | align lineheight | rtl ltr | numlist bullist | link image media table | emoticons charmap | removeformat',
        directionality: language ==='ar' ? 'rtl' : 'ltr', // Set the default direction to RTL for Arabic
        language: language || 'en', // Optional: Set TinyMCE interface language to Arabic
        content_style: `body { font-family: Arial, sans-serif; font-size: 14px; direction:${language ==='ar' ? 'rtl' : 'ltr'}; text-align:${language ==='ar' ? 'right' : 'left'} ; }`, // Ensure RTL styling
        height: 300,
      }}
      value={value}
      onEditorChange={(content) => onChange(content)}
    />
  );
}

export default TextEditor;
