import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Select,
  MenuItem,
  TextField,
  Typography,
  Paper,
  Stack,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { ArrowBack, CalendarMonth } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import TextEditor from "../common/TextEditor";
import useCreatePost from "../../hooks/useCreatePost";
import { useNavigate, useParams } from "react-router-dom";
import CustomPageHeader from "../common/CustomPageHeader";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

export default function AdvertisementSettings(props) {
  const { handleBack, handleNext } = props;
  const { t } = useTranslation();
  const {Id} = useParams()
  const navigate = useNavigate();
  const { stepData, saveData, createPost, updatePost } = useCreatePost();
  const [advertisementSettings, setAdvertisementSettings] = useState(stepData.advertisementSettings || {});
  const [advertisementTimeFrame, setAdvertisementTimeFrame] = useState(
    {...stepData.advertisementTimeFrame,validityDays:""} || {}
  );
  const handleChangeTimeFrame =(value)=>{
    setAdvertisementTimeFrame(pre=>({...pre,type:value}))
  }


const handleAdvertisementTimeFrameChange = (field, value) => {
  console.log('filed',field,value)
  setAdvertisementTimeFrame((prev) => ({
    ...prev,
    [field]: value  || null,
  }));
};

  const handleSubmit = async () => {
    if((advertisementTimeFrame.type === 'Fixed' && advertisementTimeFrame?.validityDays === "")
        || (advertisementTimeFrame.type === 'Scheduled' && (!advertisementTimeFrame?.startDate || !advertisementTimeFrame?.endDate))
    ){
      alert("sellect advertisementTimeFrame")
      return
    }

    saveData("advertisementSettings", advertisementSettings);
    saveData("advertisementTimeFrame", advertisementTimeFrame);
    let data
    if(Id){
      data = await updatePost(Id);
    }else{
      data = await createPost()
    }
    if (data.success) {
      navigate("/create/sucess");
    }
  };

  return (
   
      <Box sx={{ mx: "auto"}}>
        {/* Header */}
        <CustomPageHeader
        heading="Create Advertisement"
        buttontext="Back to Ad List"
        path="/settings"
      />

        <Stack spacing={4} mt={3}>
          {/* Re-Post/Extend Ad Section */}
          <Paper
            elevation={0}
            sx={{
              p: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              sx={{ mb: 2, color: "#F06B5B", fontWeight: "bold" }}
            >
              Select Advertisement Settings
            </Typography>
            <Stack>
              <FormControlLabel
                control={<Checkbox checked={advertisementSettings.enableStandardAds} 
                onChange={(e) =>
                  setAdvertisementSettings((prevSettings) => ({
                    ...prevSettings,
                    enableStandardAds: e.target.checked,
                  }))}
                />}
                label="Enable as Standard Ads"
                sx={{
                  "& .MuiFormControlLabel-label": { fontWeight: "bold" },
                }}
              />
              <FormControlLabel
                control={<Checkbox checked={advertisementSettings.enableCategoryFeaturedAds}
                onChange={(e) =>
                  setAdvertisementSettings((prevSettings) => ({
                    ...prevSettings,
                    enableCategoryFeaturedAds: e.target.checked,
                  }))}
                />}
                label="Enable as Category Featured Ads"
                sx={{
                  "& .MuiFormControlLabel-label": { fontWeight: "bold" },
                }}
              />
              <FormControlLabel
                control={<Checkbox checked={advertisementSettings.enableHomepageFeaturedAds}
                onChange={(e) =>
                  setAdvertisementSettings((prevSettings) => ({
                    ...prevSettings,
                    enableHomepageFeaturedAds: e.target.checked,
                  }))}
                />}
                label="Enable as Homepage Featured Ads"
                sx={{
                  "& .MuiFormControlLabel-label": { fontWeight: "bold" },
                }}
              />
              <FormControlLabel
                control={<Checkbox checked={advertisementSettings.enableMainHomepageAds} 
                onChange={(e) =>
                  setAdvertisementSettings((prevSettings) => ({
                    ...prevSettings,
                    enableMainHomepageAds: e.target.checked,
                  }))}
                />}
                label="Enable as Main Home Page Ads"
                sx={{
                  "& .MuiFormControlLabel-label": { fontWeight: "bold" },
                }}
              />
            </Stack>
          </Paper>
          <LocalizationProvider dateAdapter={AdapterDateFns}>

          <Paper
            elevation={0}
            sx={{
              p: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              sx={{ mb: 2, color: "#F06B5B", fontWeight: "bold" }}
            >
              Select Advertisement Time Frame
            </Typography>

            <RadioGroup
              value={advertisementTimeFrame.type}
              onChange={(e) => handleChangeTimeFrame(e.target.value)}
            >
              <Box spacing={3}>
                <FormControlLabel
                  value="Fixed"
                  control={<Radio />}
                  label="User Number of Days"
                />
                <FormControlLabel
                  value="Scheduled"
                  control={<Radio />}
                  label="Scheduled Number of Days"
                />
              </Box>
            </RadioGroup>

            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}
              gap={3}
            >
              <Box>
                <Typography sx={{ mb: "0px" }}>
                  Enter Validity (days)
                </Typography>
                <TextField
                  fullWidth
                  value={advertisementTimeFrame.validityDays}
                  disabled={advertisementTimeFrame.type !== 'Fixed'}
                  onChange={(e) => handleAdvertisementTimeFrameChange('validityDays',e.target.value)}
                  type="number"
                  size='small'
                />
              </Box>
              <Stack>
                <Box>
                  <Typography sx={{ mb: "0px" }}>Start Date</Typography>
                  <DatePicker
                    value={ new Date(advertisementTimeFrame.startDate)}
                    onChange={(newValue) => handleAdvertisementTimeFrameChange('startDate',newValue)}
                    slots={{
                      openPickerIcon: CalendarMonth,
                    }}
                    disabled={advertisementTimeFrame.type !== 'Scheduled'}
                    format="dd-MM-yyyy"
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        disabled:advertisementTimeFrame.type !== 'Scheduled',
                        size:'small'
                      },
                    }}
                  />
                </Box>
                <Box>
                  <Typography sx={{ mb: "0px",mt:'20px' }}>End Date</Typography>
                  <DatePicker
                    value={ new Date(advertisementTimeFrame.endDate)}
                    onChange={(newValue) => handleAdvertisementTimeFrameChange('endDate',newValue)}
                    slots={{
                      openPickerIcon: CalendarMonth,
                    }}
                    disabled={advertisementTimeFrame.type !== 'Scheduled'}
                    format="dd-MM-yyyy"
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        disabled:advertisementTimeFrame.type !== 'Scheduled',
                        size:'small'
                      },
                    }}
                  />
                </Box>
              </Stack>
            </Box>
          </Paper>
          </LocalizationProvider>
          {/* Publish Button */}
          <Paper sx={{ p: 2 }} elevation={0}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  mx: "5px",
                  textTransform: "inherit",
                  minWidth: "150px",
                  fontWeight: "bold",
                }}
                size="large"
                onClick={handleBack}
              >
                {t("BACK")}
              </Button>

              <Button
                variant="contained"
                sx={{
                  bgcolor: "primary.main",
                  textTransform: "inherit",
                  fontWeight: "bold",
                  width: 200,
                }}
                onClick={handleSubmit}
              >
                {t("PUBLISH")}
              </Button>
            </Box>
          </Paper>
        </Stack>
      </Box>
  );
}
